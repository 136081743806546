@media (max-width: 1366px) {
  .designSummaryPreview__mainPreview {
    width: 750px;
    max-width: 750px;
    min-width: 750px;
  }

  .designSummary_btn_addcoverset {
    width: 145px !important;
  }

  .designSummary_btn_downloadproject {
    width: 170px !important;
  }

  .designSummary_btn_shareproject {
    width: 140px !important;
  }
}
