.divFrameBackgroundContainer {
  background-repeat: no-repeat;
  height: 460px;
  padding: 75px;
  background-size: contain;
}

.divFrameBackground-CoverContainer {
  display: flex;
}

.frame-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.frame-shadow-container {
  display: flex;
}

.shadow {
  filter: drop-shadow(5px 5px 5px #000);
}

.shadow-vertical {
  filter: drop-shadow(5px -5px 5px #000);
}

.frame-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.frame-wrapper-vertical {
  width: calc(792px - 180px) !important;
}

.frame-content-wrapper {
  overflow: hidden;
  /* max-width: 700px; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.frame-content-wrapper-enable-selection {
  display: block !important;
}

.frame-content {
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-repeat: no-repeat;
  height: 428px;
  padding: 75px;
  background-size: contain;
}

.frame-content-small {
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-repeat: no-repeat;
  height: 428px;
  padding: 75px;
  background-size: contain;
}

.frame-content-transition {
  transition: all 250ms cubic-bezier(0.35, 0.77, 0.65, 0.93);
}

[class*="frame-content-enable-selection-horizontal-63-"],
[class*="frame-content-enable-selection-horizontal-54-"],
[class*="frame-content-enable-selection-horizontal-55-"],
[class*="frame-content-enable-selection-horizontal-59-"] {
  margin-left: calc(320px - 75px) !important;
}

[class*="frame-content-enable-selection-horizontal-70-"] {
  margin-left: calc(290px - 75px) !important;
}

.frame-content-enable-selection-vertical {
  margin-left: calc(100px - 75px) !important;
}

/* hide scrollbar in webkit browser */
.frame-content::-webkit-scrollbar,
.frame-content::-webkit-scrollbar {
  display: none;
}

.frame-content > * {
  width: 252px;
  flex-shrink: 0;
  /* flex-grow: 1; */
  /* padding-left: 300px; */
}

.frame-content > div:first-child {
  /* margin-left: 300px; */
}

/* 65mm and 55mm */
.frame-content-63-1-fold,
.frame-content-55-1-fold,
.frame-content-54-1-fold {
  min-width: 398px;
}

.frame-content-63-2-fold,
.frame-content-55-2-fold,
.frame-content-54-2-fold {
  min-width: 662px;
}

.frame-content-63-3-fold,
.frame-content-55-3-fold,
.frame-content-54-3-fold {
  min-width: 926px !important;
}

.frame-content-63-4-fold,
.frame-content-55-4-fold,
.frame-content-54-4-fold {
  min-width: 1190px;
}

.frame-content-63-5-fold,
.frame-content-55-5-fold,
.frame-content-54-5-fold {
  min-width: 1454px;
}

/* 65mm and 55mm */

/* 59mm */
.frame-content-59-1-fold {
  min-width: 398px;
}

.frame-content-59-2-fold {
  min-width: 624px;
}

.frame-content-59-3-fold {
  min-width: 848px !important;
}

.frame-content-59-4-fold {
  min-width: 1063px;
}

/* 59mm */

/* 70mm */
.frame-content-70-1-fold {
  min-width: 465px;
}

.frame-content-70-2-fold {
  min-width: 712px;
}

.frame-content-70-3-fold {
  min-width: 996px !important;
}

.frame-content-70-4-fold {
  min-width: 1280px;
}

.frame-content-70-5-fold {
  min-width: 1564px;
}

/* 70mm */

.frame-cover-enable-selection {
  cursor: pointer !important;
}

.left-arrow {
  left: 0px;
  top: -130px;
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 60%, rgb(255, 255, 255) 100%);
  position: absolute;
  height: 727px;
  width: 250px;
  z-index: 99;
  cursor: pointer;
}

.left-arrow-vertical {
  width: 100px !important;
  height: 891px !important;
  top: -230px !important;
  background: transparent;
}

.right-arrow {
  right: 0px;
  top: -130px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 60%, rgb(255, 255, 255) 100%);
  position: absolute;
  height: 727px;
  width: 250px;
  z-index: 99;
  cursor: pointer;
}

.right-arrow-vertical {
  width: 200px !important;
  height: 891px !important;
  top: -230px !important;
}
