.design-preview-download-project-dialog-content {
  width: 438px !important;
  padding-left: 32px 24px !important;
  gap: 24px;
}

.design-preview-download-project-dialog-title {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 21px !important;
  line-height: 26px !important;
  color: #000000 !important;
}

.design-preview-download-project-dialog-input-label {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  padding-top: 24px;
}

.design-preview-download-project-dialog-cancel {
  width: 193px;
  height: 41px;
  text-transform: capitalize !important;
}

.design-preview-download-project-dialog-download {
  width: 193px;
  height: 41px;
  background: #009fe3;
  text-transform: capitalize !important;
}

.design-preview-download-project-dialog-input {
  width: 195.5px;
  height: 41px;
  background: #ffffff;
  border: 1px solid #dbdbdb !important;
  text-transform: capitalize !important;
}

.design-preview-download-project-dialog-input-active {
  width: 195.5px;
  height: 41px;
  background: #e7f8ff !important;
  text-transform: capitalize !important;
}
