.gridView__item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 100%;
  max-width: 170px;
  padding: 5px 20px;
}

.gridView__selectedItem {
  outline: 3px solid #009fe3;
  box-shadow:
    0px 0px 1px rgba(15, 15, 15, 0.08),
    0px 0px 2px rgba(15, 15, 15, 0.04),
    0px 4px 8px rgba(102, 102, 102, 0.16);
  border-radius: 4px;
}
