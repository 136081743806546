.divFrameBackgroundContainer-thumb {
  background-repeat: no-repeat;
  height: 460px;
  padding: 75px;
  background-size: contain;
}

.divFrameBackground-CoverContainer-thumb {
  display: flex;
}

.frame-container-thumb-horizontal {
  width: 100%;
  display: flex;
  flex-direction: column;
  transform: scale(0.1);
  margin-left: 0;
  margin-top: -167px;
}

.frame-container-thumb-vertical {
  width: 100%;
  display: flex;
  flex-direction: column;
  transform: scale(0.1);
  margin-left: -22px;
  margin-top: -26px;
  max-height: 98px;
}

.frame-wrapper-thumb {
  display: flex;
  width: 100%;
  position: relative;
}

.frame-wrapper-vertical-thumb {
  width: calc(792px - 400px) !important;
}

.frame-content-wrapper-thumb {
  /* overflow: hidden; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.frame-content-wrapper-enable-selection-thumb {
  display: block !important;
}

.frame-content-thumb {
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-repeat: no-repeat;
  height: 428px;
  background-size: contain;
}

.frame-content-small-thumb {
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-repeat: no-repeat;
  height: 428px;
  padding: 75px;
  background-size: contain;
}

.frame-content-transition-thumb {
  transition: all 250ms cubic-bezier(0.35, 0.77, 0.65, 0.93);
}

.frame-content-enable-selection-horizontal-thumb {
  margin-left: calc(310px - 75px) !important;
}

.frame-content-enable-selection-vertical-thumb {
  margin-left: calc(100px - 75px) !important;
}

/* hide scrollbar in webkit browser */
.frame-content-thumb::-webkit-scrollbar,
.frame-content-thumb::-webkit-scrollbar {
  display: none;
}

.frame-content-thumb > * {
  width: 252px;
  flex-shrink: 0;
  /* flex-grow: 1; */
  /* padding-left: 300px; */
}

.frame-content-thumbdiv:first-child {
  /* margin-left: 300px; */
}

.frame-content-1-fold-thumb {
  min-width: 428px;
}

.frame-content-2-fold-thumb {
  min-width: 712px;
}

.frame-content-3-fold-thumb {
  min-width: 996px !important;
}

.frame-content-4-fold-thumb {
  min-width: 1280px;
}

.frame-content-5-fold-thumb {
  min-width: 1564px;
}

.frame-cover-enable-selection-thumb {
  cursor: pointer !important;
}

.left-arrow {
  left: 0px;
  top: -130px;
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 60%, rgb(255, 255, 255) 100%);
  position: absolute;
  height: 727px;
  width: 250px;
  z-index: 99;
  cursor: pointer;
}

.left-arrow-vertical {
  width: 100px !important;
  height: 891px !important;
  top: -230px !important;
  background: transparent;
}

.right-arrow {
  right: 0px;
  top: -130px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 60%, rgb(255, 255, 255) 100%);
  position: absolute;
  height: 727px;
  width: 250px;
  z-index: 99;
  cursor: pointer;
}

.right-arrow-vertical {
  width: 200px !important;
  height: 891px !important;
  top: -230px !important;
}
