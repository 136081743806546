.login {
  min-height: 100vh;
  background-color: #f1f1f1;
}

.formContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 440px;
}

.formContainer__login {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 240px;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  position: relative;
  z-index: 1;
  background: inherit;
  overflow: hidden;
}

.formContainer__login:before {
  content: "";
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
  filter: blur(10px);
  margin: -20px;
}

.login_logo_bje {
  width: 140px;
  margin-left: -16px;
}

.login_logo_abb {
  width: 90px;
  height: 40px;
}

.formContainer__login > h1 {
  font-size: 1.8rem;
  font-weight: 700;
}

.formContainer__text {
  font-size: 0.95rem;
  font-weight: 400;
}

.formContainer__text > a {
  text-decoration: underline;
  font-weight: 500;
}

.formContainer__btn {
  padding: 7px !important;
  margin: 18px 0 !important;
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  letter-spacing: 1.1px !important;
}

.formContainer__guestBtn {
  margin-top: 20px;
  width: 100%;
  height: 100%;
  min-height: 50px;
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  justify-content: center;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  position: relative;
  z-index: 1;
  background: inherit;
  overflow: hidden;
  outline: none;
  border: 0;
  background-color: transparent;
  text-align: start;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
}

.formContainer__guestBtn:before {
  content: "";
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
  filter: blur(10px);
  margin: -20px;
}

.formContainer__guestBtn:hover {
  cursor: pointer;
}

@media only screen and (max-width: 480px) {
  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .formContainer {
    margin-top: -20%;
    position: unset;
    left: auto;
    top: auto;
    transform: none;
  }

  .formContainer__login {
    padding: 20px;
  }
  .formContainer__guest {
    padding: 5px 20px;
    height: 100%;
  }
}
