.grid-line-switch-label {
  line-height: 16px !important;
  color: #1f1f1f !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.grid-line-switch-info {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #1d1d1b !important;
  margin-top: 6px !important;
  margin-left: 40px !important;
}

.MuiSwitch-root {
  width: 50px !important;
}

.MuiSwitch-switchBase.Mui-checked {
  color: #ffffff !important;
}

.MuiSwitch-thumb {
  width: 12.5px !important;
  height: 12.5px !important;
  margin-top: 4px !important;
  margin-left: 4px !important;
}

.MuiSwitch-track {
  width: 30px !important;
  height: 15px !important;
}
