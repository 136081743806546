.designSummary {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.designSummary__headerGrid {
  height: 80px;
  padding: 0px 0px 9px 0px;
  margin-top: 40px;
}

.designSummary__main {
  display: flex;
  justify-content: center;
}

.designSummary__groupTitle {
  display: flex;
}

.designSummary__title {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 32px !important;
  line-height: 40px !important;
}

.designSummary__description {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #696969;
  margin-top: 5px !important;
}

.designSummary__projectIcon {
  width: 35px;
  height: 35px;
  margin-top: 3px !important;
  cursor: pointer !important;
  margin-left: 16px !important;
}

.designSummaryPreview__mainPreview {
  border-radius: 4px;
  height: 100%;
  position: relative;
  flex: 1.6;
  align-self: stretch;
  min-height: 727px;
  padding-right: 16px;
  max-width: 891px;
  min-width: 891px;
}

.designSummary__mainSummary {
  width: 435px;
  height: 421px;
  padding: 24px 25px 21px 25px;
  background-color: #fafafa;
  border-radius: 4px;
}

.designSumary__mainDescription {
  margin-top: 12px;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 17px !important;
}

.bje_designSummary__container_info {
  margin-top: 24px;
  display: flex;
  width: 387px;
  height: 75px;
  border: 1px solid #1b7ac2;
  border-radius: 4px;
  background: #def4fa;
  padding: 12px 16px;
  gap: 10px !important;
  align-items: center !important;
}

.abb_designSummary__container_info {
  margin-top: 24px;
  display: flex;
  width: 387px;
  height: 75px;
  border-radius: 4px;
  border-left: 2px solid #000;
  background: var(--primary-color-background-primary-white, #fff);
  box-shadow:
    0px 2px 8px 0px rgba(90, 90, 90, 0.1),
    0px 4px 20px 0px rgba(72, 72, 72, 0.1);
  padding: 12px 16px;
  gap: 10px !important;
  align-items: center !important;
}

.designSummary__info {
  width: 329px;
  height: 51px;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  display: flex;
  align-items: center;
}

.designSummary__mainSummaryDivider {
  border-top: 1px solid #dbdbdb;
  margin: 24px 0 0 0;
  width: 100%;
}

.designSummary__mainSummary h4 {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

.designSummary_coverSetsMessage {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.designSummary_divider {
  height: 16px !important;
  margin: 8px 8px !important;
}

.designSummary_OrderTitle,
.designSummary_OrderValue,
.designSummary_OrderTitle {
  font-size: 18px !important;
  font-weight: 400px !important;
  color: #1d1d1b !important;
}

.designSummary_OrderSubtitle {
  font-size: 12px !important;
  font-weight: 700px !important;
  color: #9f9f9f !important;
}

.designSummary_TotalValue {
  font-family: ABBvoice;
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: right;
}

.designPreview__mainSummaryAlert {
  height: 42px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  border: 1px solid #1b7ac2;
  background: #def4fa;
  border-radius: 4px;
  font-size: 14px;
}

.designSummary__mainSummaryButtons {
  margin-top: 24px;
  margin-bottom: 16px;
}

.designSummary__mainSummaryButtons > button {
  text-transform: unset !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 140% !important;
  padding: 15.5px 0 !important;
}

.designSummary__mainSummaryButtons button:nth-child(1) {
  width: 100%;
  width: 100%;
  text-transform: unset;
  font-style: normal !important;
  line-height: 140% !important;
}

.designSummary__mainSummaryButtons .disabled {
  background-color: #bababa !important;
}

.designSummary__mainSummaryReviewMessagem {
  font-size: 14px !important;
  font-weight: 400 !important;
  text-align: center;
}

/* TABLE */

.designSummary__table {
  width: 891px;
  margin-top: 37px;
  overflow: hidden;
}

.designSummary__thead {
  width: 891px;
}

.designSummary__thead_preview {
  width: 168px;
}

.designSummary__thead_details {
  width: 242px;
}

.designSummary__thead_quantity {
  width: 183px;
}

.designSummary__thead_price {
  width: 150px;
}

.designSummary__thead_status {
  width: 82px;
}

.designSummary__thead_dots {
  width: 80px;
}

.designSummaryTable_cell {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #696969 !important;
  border-bottom: 1px solid #dbdbdb !important;
  padding: 8px !important;
}

.designSummaryTable_row {
  height: 130px;
  padding: 16px 0px;
}

.designSummaryTable_cell_container {
  max-width: 240px;
}

.designSummaryTable_cell_cover {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: #000;
  gap: 4px;
}
.designSummaryTable_cell_cover .detail {
  color: #696969;
  font-weight: 400;
}

.designSummaryTable_cell_designrange {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1d1d1b;
  gap: 4px;
}

.designSummaryTable_cell_designrange .detail {
  line-height: 18px;
  color: #696969;
}

.designSummaryTable_cell_numbercovers {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1d1d1b;
}

.designSummaryTable_cell_seedetails {
  text-transform: unset !important;
  padding: 0px !important;
}

.designSummaryTable_cell_numbercovers .detail {
  margin-left: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #696969;
}

.designSummaryTable_cell_iconquantity {
  padding: 0px !important;
}

.designSummaryTable_cell_quantity_bje {
  padding: 0 !important;
  margin: 0 5px !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 4px !important;
  width: 42px !important;
  height: 42px !important;
}

.designSummaryTable_cell_quantity_bje_draft {
  padding: 0px !important;
  margin: 0 5px !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 4px !important;
  background-color: #ebebeb !important;
  width: 42px !important;
  height: 42px !important;
}

.designSummaryTable_cell_bje_draft {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  border-bottom: 1px solid #dbdbdb !important;
  padding: 8px !important;
  color: #009ee2 !important;
}

.designSummaryTable_cell_completed {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #696969 !important;
  border-bottom: 1px solid #dbdbdb !important;
  padding: 8px !important;
}

.designSummaryTable__abb_table_head {
  height: 49px;
  background: var(--primary-color-background-secondary-white, #fafafa);
}

.designSummary_favoriteDesignBadge {
  position: relative;
  bottom: 16px;
  left: 72px;
  background-color: var(--primary-color-background-secondary-white, #fafafa);
  border: 1px solid lightgrey;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.designSummary_favoriteDesignBadge .MuiBadge-badge {
  font-size: unset;
  transform: none;
  position: unset;
  background-color: white;
}

.designSummary_favoriteDesignIcon {
  padding-top: 1px;
  width: 16px !important;
  height: 16px !important;
}
