.thumb {
  width: 140px;
  height: 98px;
  border: 1px solid #f5f5f5;
  background: linear-gradient(316.08deg, #f5f5f5 52.92%, rgba(207, 207, 207, 0.36) 100%) !important;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.designPreview__mainPreview--horizontal-thumb {
  transition: rotate(0deg);
  max-height: 98px;
  max-width: 140px;
}

.designPreview__mainPreview--vertical-thumb {
  transform: rotate(90deg);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 98px;
  max-width: 140px;
}
