.ProfileImg {
  background-size: cover;
  height: 158px;
}

@media (max-width: 1440px) {
  .ProfileImg {
    background-size: contain;
  }
}
