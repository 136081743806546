.frame-configuration-design-range-dialog-content {
  width: 438px !important;
  height: 225px !important;
  padding: 32px 24px !important;
  border-radius: 4px !important;
}

.frame-configuration-design-range-dialog-title {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 21px !important;
  line-height: 26px !important;
  color: #000000 !important;
}

.frame-configuration-design-range-dialog-description {
  margin-top: 4px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #464646 !important;
}

.frame-configuration-design-range-buttons {
  padding-top: 24px;
  display: flex;
  justify-content: flex-end;
}

.frame-configuration-design-range-dialog-cancel {
  width: 120px;
  height: 41px;
  text-transform: capitalize !important;
  justify-content: center !important;
  left: -15px !important;
}

.frame-configuration-design-range-dialog-confirm {
  width: 120px;
  height: 41px;
  background: #009fe3;
  text-transform: capitalize !important;
}
