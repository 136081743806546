@media (max-width: 1366px) {
  .projectSummaryPreview__mainPreview {
    width: 750px;
    max-width: 750px;
    min-width: 750px;
  }

  .projectSummary_btn_addcoverset {
    width: 145px !important;
  }

  .projectSummary_btn_downloadproject {
    width: 170px !important;
  }

  .projectSummary_btn_shareproject {
    width: 140px !important;
  }
}
