.preview-live-content {
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
}

.preview-live-content.vertical {
  transform: rotate(270deg);
  position: absolute;
  left: 400px;
  bottom: 640px;
  z-index: 9999;
}

.preview-live-content.vertical > button {
  left: 0px;
}

.preview-live-content > button {
  text-transform: capitalize;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  z-index: 99999;
  left: 30px;
  align-items: center;
  padding: 8px 12px;
}

.preview-live-content > button > span {
  margin-right: 0;
}
