.demoContainer {
  display: flex;
  align-items: stretch;
  column-gap: 16px;
  margin-bottom: 16px;
}

.favoriteContainer {
  width: 100%;
}
