.selectExistingProjectDialogBody__formControlContainer {
  margin-top: 22px;
  margin-bottom: 22px;
}

.selectExistingProjectDialogBody__formControlContainer small {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #616161;
}

.selectExistingProjectDialogBody__formControlInput {
  margin: 5px 0 5px 0;
  padding: 10px 16px;
  outline: none;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  transition: 0.4 border-color;
  font-size: 14px;
  transition: 0.4s border-color;
}

.selectExistingProjectDialogBody__formControlInput:focus {
  border-color: var(--busch-medium-blue);
}
