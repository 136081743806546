.nav__linkContainer {
  position: relative;
}

.nav__link {
  font-weight: 400;
  font-size: 16px;
  color: #000;
  margin-right: 20px;
}

.nav__link--active {
  font-weight: 700;
}

.nav__link:last-child {
  margin-right: 20px;
}

.nav__linkBorder {
  width: 100%;
  position: absolute;
  bottom: -17px;
  left: -8px;
}
