.frameConfiguration_cell_quantity_abb {
  padding: 0px !important;
  margin: 0 5px !important;
  border-radius: 4px !important;
  color: #1d1d1b !important;
  width: 42px !important;
  height: 100% !important;
}

.frameConfiguration_cell_quantity_bje {
  padding: 0px !important;
  margin: 0 5px !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 4px !important;
  color: #1d1d1b !important;
  width: 42px !important;
  height: 100% !important;
}

.frameConfiguration__backToSwitchSelectionBtn {
  display: flex !important;
  align-items: center !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  text-transform: unset !important;
  line-height: 140% !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 0px !important;
  color: #1d1d1b !important;
}

.frameConfiguration__headerContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 1310px;
  margin: 4px 0 4px 0;
}

.frameConfiguration__headerContainerButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 891px;
}

.frameConfiguration__mainContainer {
  width: 100% !important;
  display: flex;
  justify-content: center;
}

.frameConfiguration__main {
  justify-content: center;
  align-items: center;
  width: 1328px;
}

.frameConfiguration__headerGrid {
  display: flex;
  justify-content: center;
}

.frameConfiguration__mainPreview {
  background: linear-gradient(316.08deg, #f5f5f5 52.92%, rgba(207, 207, 207, 0.36) 100%);
  border-radius: 4px;
  height: 100%;
  position: relative;
  flex: 1.6;
  align-self: stretch;
  width: 891px;
  min-width: 891px;
  max-width: 891px;
  min-height: 727px;
}

.frameConfiguration__mainPreviewContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 40px;
}

.frameConfiguration__mainPreviewContentfuture {
  top: 60%;
  transform: translate(-50%, -60%);
}

.frameConfiguration__mainPreviewTitle-horizontal {
  text-align: center;
  z-index: 1;
  padding: 20px 0;
  margin-top: 90px;
}

.frameConfiguration__mainPreviewTitle-vertical {
  text-align: center;
  z-index: 1;
  padding: 20px 0;
  margin-top: 30px;
}

.frameConfiguration__selectedDesignNameText {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 140%;
  color: #1d1d1b;
  margin-block-start: 0;
  margin-block-end: 0;
}

.frameConfiguration__changeBtn {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 140% !important;
  text-transform: unset !important;
  z-index: 99;
}

.frameConfiguration__mainPreviewImage--horizontal {
  width: 100%;
  /* max-width: 320px; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.frameConfiguration__mainPreviewImage--horizontal img {
  height: auto;
  width: 100%;
  /* max-width: 320px; */
  /* max-height: 320px; */
  object-fit: cover;
  user-select: none;
}

.frameImg__carat-1--horizontal,
.insertImg__carat1-1--horizontal {
  width: 100% !important;
  max-width: 320px;
}

.frameImg__carat-2--horizontal,
.insertImg__carat1-2--horizontal,
.insertImg__carat2-2--horizontal {
  width: 100% !important;
  /* max-width: 550px; */
}

.frameImg__carat-3--horizontal,
.insertImg__carat1-3--horizontal,
.insertImg__carat2-3--horizontal,
.insertImg__carat3-3--horizontal {
  width: 100% !important;
  /* max-width: 620px; */
}

.frameImg__carat-4--horizontal,
.insertImg__carat1-4--horizontal,
.insertImg__carat2-4--horizontal,
.insertImg__carat3-4--horizontal,
.insertImg__carat4-4--horizontal {
  width: 100% !important;
  /* max-width: 700px; */
}

.frameImg__buschbalancesi-1--horizontal,
.insertImg__buschbalancesi1-1--horizontal {
  width: 100% !important;
  max-width: 320px;
}

.frameImg__buschbalancesi-2--horizontal,
.insertImg__buschbalancesi1-2--horizontal,
.insertImg__buschbalancesi2-2--horizontal {
  width: 100% !important;
  /* max-width: 550px; */
}

.frameImg__buschbalancesi-3--horizontal,
.insertImg__buschbalancesi1-3--horizontal,
.insertImg__buschbalancesi2-3--horizontal,
.insertImg__buschbalancesi3-3--horizontal {
  width: 100% !important;
  /* max-width: 620px; */
}

.frameImg__buschbalancesi-4--horizontal,
.insertImg__buschbalancesi1-4--horizontal,
.insertImg__buschbalancesi2-4--horizontal,
.insertImg__buschbalancesi3-4--horizontal,
.insertImg__buschbalancesi4-4--horizontal {
  width: 100% !important;
  /* max-width: 700px; */
}

.frameImg__buschbalancesi-5--horizontal,
.insertImg__buschbalancesi1-5--horizontal,
.insertImg__buschbalancesi2-5--horizontal,
.insertImg__buschbalancesi3-5--horizontal,
.insertImg__buschbalancesi4-5--horizontal,
.insertImg__buschbalancesi5-5--horizontal {
  width: 100% !important;
  /* max-width: 700px; */
}

.frameConfiguration__mainPreviewImage--vertical {
  transform: rotate(90deg);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.frameConfiguration__mainPreviewImage--vertical img {
  height: auto;
  /* width: 100%; */
  max-width: 420px;
  /* max-height: 500px; */
  object-fit: cover;
  user-select: none;
}

.frameImg__carat-2--vertical,
.insertImg__carat1-2--vertical,
.insertImg__carat2-2--vertical {
  max-width: 420px;
}

.frameImg__carat-3--vertical,
.insertImg__carat1-3--vertical,
.insertImg__carat2-3--vertical,
.insertImg__carat3-3--vertical {
  max-width: 420px;
}

.frameImg__carat-4--vertical,
.insertImg__carat1-4--vertical,
.insertImg__carat2-4--vertical,
.insertImg__carat3-4--vertical,
.insertImg__carat4-4--vertical {
  max-width: 420px;
}

.frameImg__buschbalancesi-2--vertical,
.insertImg__buschbalancesi1-2--vertical,
.insertImg__buschbalancesi2-2--vertical {
  max-width: 420px;
}

.frameImg__buschbalancesi-3--vertical,
.insertImg__buschbalancesi1-3--vertical,
.insertImg__buschbalancesi2-3--vertical,
.insertImg__buschbalancesi3-3--vertical {
  max-width: 420px;
}

.frameImg__buschbalancesi-4--vertical,
.insertImg__buschbalancesi1-4--vertical,
.insertImg__buschbalancesi2-4--vertical,
.insertImg__buschbalancesi3-4--vertical,
.insertImg__buschbalancesi4-4--vertical {
  max-width: 420px;
}

.frameImg__buschbalancesi-5--vertical,
.insertImg__buschbalancesi1-5--vertical,
.insertImg__buschbalancesi2-5--vertical,
.insertImg__buschbalancesi3-5--vertical,
.insertImg__buschbalancesi4-5--vertical,
.insertImg__buschbalancesi5-5--vertical {
  max-width: 420px;
}

/* .frameConfiguration__mainPreviewImage--vertical img:not(:first-child){
    transform: rotate(-90deg) scale(.61);
} */

/* INSERT CARAT */

.insertImg__carat1-1--horizontal {
  position: absolute;
  transform: scale(1.03);
}

.insertImg__carat1-1--vertical {
  position: absolute;
  transform: scale(1.03);
}

.insertImg__carat1-2--horizontal {
  position: absolute;
  left: -20%;
  transform: scale(0.605);
}

.insertImg__carat2-2--horizontal {
  position: absolute;
  left: 19.9%;
  transform: scale(0.605);
}

.insertImg__carat1-2--vertical {
  position: absolute;
  left: -42%;
  top: -33.2%;
  transform: rotate(-90deg) scale(0.61);
}

.insertImg__carat2-2--vertical {
  position: absolute;
  left: 10.4%;
  top: -33.2%;
  transform: rotate(-90deg) scale(0.61);
}

.insertImg__carat1-3--horizontal {
  position: absolute;
  left: -28.5%;
  transform: scale(0.433);
}

.insertImg__carat2-3--horizontal {
  position: absolute;
  left: 0%;
  transform: scale(0.433);
}

.insertImg__carat3-3--horizontal {
  position: absolute;
  left: 28.5%;
  transform: scale(0.433);
}

.insertImg__carat1-3--vertical {
  position: absolute;
  left: -53.1%;
  top: -66.3%;
  transform: rotate(-90deg) scale(0.438);
}

.insertImg__carat2-3--vertical {
  position: absolute;
  left: -15.7%;
  top: -66.3%;
  transform: rotate(-90deg) scale(0.438);
}

.insertImg__carat3-3--vertical {
  position: absolute;
  left: 21.6%;
  top: -66.3%;
  transform: rotate(-90deg) scale(0.438);
}

.insertImg__carat1-4--horizontal {
  position: absolute;
  left: -33.3%;
  transform: scale(0.335);
}

.insertImg__carat2-4--horizontal {
  position: absolute;
  position: absolute;
  left: -11%;
  transform: scale(0.335);
}

.insertImg__carat3-4--horizontal {
  position: absolute;
  position: absolute;
  position: absolute;
  left: 11%;
  transform: scale(0.335);
}

.insertImg__carat4-4--horizontal {
  position: absolute;
  position: absolute;
  left: 33.3%;
  transform: scale(0.335);
}

.insertImg__carat1-4--vertical {
  position: absolute;
  left: -59.3%;
  top: -99.3%;
  transform: rotate(-90deg) scale(0.335);
}

.insertImg__carat2-4--vertical {
  position: absolute;
  position: absolute;
  left: -30.2%;
  top: -99.3%;
  transform: rotate(-90deg) scale(0.335);
}

.insertImg__carat3-4--vertical {
  position: absolute;
  position: absolute;
  position: absolute;
  left: -1.4%;
  top: -99.3%;
  transform: rotate(-90deg) scale(0.335);
}

.insertImg__carat4-4--vertical {
  position: absolute;
  position: absolute;
  left: 27.7%;
  top: -99.3%;
  transform: rotate(-90deg) scale(0.335);
}

/* INSERT CARAT */

/* INSERT BALANCESI */

.insertImg__buschbalancesi1-1--horizontal {
  position: absolute;
  transform: scale(0.88);
}

.insertImg__buschbalancesi1-1--vertical {
  position: absolute;
  transform: scale(0.88);
}

.insertImg__buschbalancesi1-2--horizontal {
  position: absolute;
  left: -19.5%;
  transform: scale(0.54);
}

.insertImg__buschbalancesi2-2--horizontal {
  position: absolute;
  left: 19.5%;
  transform: scale(0.54);
}

.insertImg__buschbalancesi1-2--vertical {
  position: absolute;
  left: -41%;
  top: -32%;
  transform: rotate(-90deg) scale(0.54);
}

.insertImg__buschbalancesi2-2--vertical {
  position: absolute;
  left: 10.4%;
  top: -32%;
  transform: rotate(-90deg) scale(0.54);
}

.insertImg__buschbalancesi1-3--horizontal {
  position: absolute;
  left: -28.4%;
  transform: scale(0.388);
}

.insertImg__buschbalancesi2-3--horizontal {
  position: absolute;
  left: 0%;
  transform: scale(0.388);
}

.insertImg__buschbalancesi3-3--horizontal {
  position: absolute;
  left: 28%;
  transform: scale(0.388);
}

.insertImg__buschbalancesi1-3--vertical {
  position: absolute;
  left: -52.6%;
  top: -63.3%;
  transform: rotate(-90deg) scale(0.388);
}

.insertImg__buschbalancesi2-3--vertical {
  position: absolute;
  left: -15.7%;
  top: -63.3%;
  transform: rotate(-90deg) scale(0.388);
}

.insertImg__buschbalancesi3-3--vertical {
  position: absolute;
  left: 21.6%;
  top: -63.3%;
  transform: rotate(-90deg) scale(0.388);
}

.insertImg__buschbalancesi1-4--horizontal {
  position: absolute;
  left: -33.1%;
  transform: scale(0.3);
}

.insertImg__buschbalancesi2-4--horizontal {
  position: absolute;
  position: absolute;
  left: -11%;
  transform: scale(0.3);
}

.insertImg__buschbalancesi3-4--horizontal {
  position: absolute;
  position: absolute;
  position: absolute;
  left: 11%;
  transform: scale(0.3);
}

.insertImg__buschbalancesi4-4--horizontal {
  position: absolute;
  position: absolute;
  left: 32.8%;
  transform: scale(0.3);
}

.insertImg__buschbalancesi1-4--vertical {
  position: absolute;
  left: -58.3%;
  top: -96.3%;
  transform: rotate(-90deg) scale(0.3);
}

.insertImg__buschbalancesi2-4--vertical {
  position: absolute;
  position: absolute;
  left: -30.2%;
  top: -96.3%;
  transform: rotate(-90deg) scale(0.3);
}

.insertImg__buschbalancesi3-4--vertical {
  position: absolute;
  position: absolute;
  position: absolute;
  left: -1.4%;
  top: -96.3%;
  transform: rotate(-90deg) scale(0.3);
}

.insertImg__buschbalancesi4-4--vertical {
  position: absolute;
  position: absolute;
  left: 27.7%;
  top: -96.3%;
  transform: rotate(-90deg) scale(0.3);
}

.insertImg__buschbalancesi1-5--horizontal {
  position: absolute;
  left: -36.1%;
  transform: scale(0.25);
}

.insertImg__buschbalancesi2-5--horizontal {
  position: absolute;
  position: absolute;
  left: -18%;
  transform: scale(0.25);
}

.insertImg__buschbalancesi3-5--horizontal {
  position: absolute;
  position: absolute;
  position: absolute;
  left: 0%;
  transform: scale(0.25);
}

.insertImg__buschbalancesi4-5--horizontal {
  position: absolute;
  position: absolute;
  left: 18%;
  transform: scale(0.25);
}

.insertImg__buschbalancesi5-5--horizontal {
  position: absolute;
  position: absolute;
  left: 36.1%;
  transform: scale(0.25);
}

.insertImg__buschbalancesi1-5--vertical {
  position: absolute;
  left: -63.1%;
  transform: scale(0.25);
}

.insertImg__buschbalancesi2-5--vertical {
  position: absolute;
  position: absolute;
  left: -39.4%;
  transform: scale(0.25);
}

.insertImg__buschbalancesi3-5--vertical {
  position: absolute;
  position: absolute;
  position: absolute;
  left: -16%;
  transform: scale(0.25);
}

.insertImg__buschbalancesi4-5--vertical {
  position: absolute;
  position: absolute;
  left: 7.6%;
  transform: scale(0.25);
}

.insertImg__buschbalancesi5-5--vertical {
  position: absolute;
  position: absolute;
  left: 31.1%;
  transform: scale(0.25);
}

/* INSERT BALANCESI */

.frameConfiguration__mainParameters {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 437px;
  min-width: 437px;
  max-width: 437px;
  height: 545px;
  padding: 0px 16px;
}

.frameConfiguration__mainParameters.with-margin-top {
  margin-top: 48px;
}

.frameConfiguration__mainParameters h4 {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

.frameConfiguration__mainParametersTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  color: #1d1d1b;
}

.frameConfiguration__mainParametersDivider {
  border-top: 1px solid #bababa;
  margin: 24px 0;
  width: 100%;
  max-width: 380px;
}

.frameConfiguration__mainParameterFrameColorLabel {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #1d1d1b;
  margin-bottom: 24px;
}

.frameConfiguration__mainParameterFrameColorContainer {
  width: 70%;
  margin: 24px 0 40px 0;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 16px;
}

.frameConfiguration__mainParameterFrameColorItem {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4px;
  background-color: yellow;
}

.frameConfiguration__mainParameterSwitchesCounter {
  margin-top: 24px;
  margin-bottom: 19px;
}

.frameConfiguration__mainParameterInfo {
  display: flex;
  align-items: center;
}

.frameConfiguration__mainParametersNextButton > button {
  text-transform: unset !important;
  font-style: normal !important;
  font-size: 18px !important;
  line-height: 140% !important;
  padding: 15.5px 0 !important;
}

.frameConfiguration__mainParametersNextButton > button > svg {
  margin-left: 11px;
}

.frameConfiguration__needHelpDiv {
  display: flex;
  justify-content: center;
  padding: 18px 0px;
}

.frameConfiguration__needHelpDivInside {
  width: 1310px;
  display: flex;
  justify-content: flex-start;
}

.frameConfiguration__needHelpBtn {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 140% !important;
  text-transform: unset !important;
}

.frameConfiguration__needHelpBtn > svg {
  margin-right: 9px;
}

.frameConfiguration__mainParameterSwitchesOrientation {
  margin: 10px 0 15px 0;
}

.frameConfiguration__mainParameterSwitchesOrientationButtons {
  display: flex;
  align-items: stretch;
  column-gap: 12px;
}

.frameConfiguration__projectName {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #696969;
}

.frameConfiguration__projectName > span {
  margin-left: 4px;
  color: #000;
}

@media only screen and (max-width: 1333px) {
  .frameConfiguration__mainPreview {
    width: 823px;
    min-width: 823px;
    max-width: 823px;
  }
}
