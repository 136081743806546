.project__header_image {
  background-repeat: no-repeat;
  background-size: cover;
  height: 158px;
}

.project__title {
  font-size: 32px !important;
  font-weight: 700 !important;
  line-height: normal !important;
  list-style: normal !important;
}

.project__description {
  color: #696969 !important;
  font-size: 16px !important;
  font-style: normal !important;
  line-height: normal !important;
}

.projects__table_header_cell {
  color: var(--grey-50, #868686) !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  padding: 0 0 0 30px !important;
}

.projects__table_header_cell.name {
  width: 417px;
  padding-left: 0 !important;
}

.projects__table_row_cell {
  color: var(--grey-90, #1f1f1f) !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  font-style: normal !important;
  line-height: normal !important;
  padding-left: 30px !important;
}
.projects__table_row_cell_checkbox {
  color: var(--grey-90, #1f1f1f) !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  font-style: normal !important;
  line-height: normal !important;
  height: 16px !important;
  width: 16px !important;
  padding-left: 0 !important;
}

.projects__table_row_cell.name {
  color: var(--brand-primary-black, #000) !important;
  font-weight: 700 !important;
  padding-left: 0 !important;
}
.projects__accordion_label {
  color: var(--brand-primary-black, #000) !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.projects__abb_table_head {
  height: 49px;
  border-radius: 4px;
  background: var(--primary-color-background-secondary-white, #fafafa);
}
.designSummary__table {
  margin-top: 56px !important;
}
.projects__table_cell_spacing {
  width: 42px;
}

.projects__table_cell_with_buttons {
  white-space: nowrap;
}