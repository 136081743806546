.designPreviewContent {
  background: linear-gradient(316.08deg, #f5f5f5 52.92%, rgba(207, 207, 207, 0.36) 100%);
  border-radius: 4px;
}

.designPreviewBackground {
  padding: 30px;
  border-radius: 4px;
}

.designPreview__mainPreview--horizontal-dialog {
  transition: rotate(0deg);
}

.designPreview__mainPreview--vertical-dialog {
  transform: rotate(90deg) scale(0.7);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.designPreview__tabs {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #000000;
  text-transform: capitalize;
}

.designPreview__tabs_container_title {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 140% !important;
  color: #1d1d1b;
  margin-bottom: 8px !important;
}

.designPreview__tabs_container_key {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1d1d1b;
}

.designPreview__tabs_container_value {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #696969;
  margin-left: 4px;
}
