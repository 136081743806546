:root {
  --tile-54-size: 204.09448819;
  --tile-54-padding: 12;
  --tile-54-size-without-padding: calc(var(--tile-54-size) - (2 * var(--tile-54-padding)));
  --tile-54-separation-line-width: 1;
  --tile-54-space-for-separation-line: 2;
  --tile-54-one-rocker-space-between-tiles: 1;
  --tile-54-two-rockers-space-between-tiles: 1;
  --tile-54-one-button-one-rocker-space-between-tiles: 1;
  --tile-54-two-buttons-one-rocker-space-between-tiles: 1;
  --tile-54-one-slider-one-rocker-space-between-tiles: 1;
  --tile-54-slider-two-buttons-space-between-tiles: 4;
}

.tile-54 {
  height: calc(var(--tile-54-size) * 1px);
  padding: calc(var(--tile-54-padding) * 1px);
}

.tile-54 .container {
  --container-space-between-tiles: calc(1 * var(--tile-54-space-for-separation-line));
  width: calc((var(--tile-54-size-without-padding) - var(--container-space-between-tiles)) / 2 * 1px);
  max-width: calc((var(--tile-54-size-without-padding) - var(--container-space-between-tiles)) / 2 * 1px);
}

.tile-54 .tile-div-canvas {
  flex: 1 1 auto;
  display: flex;
  /* margin-top: 3px; */
  flex-direction: column;
  position: absolute;
  top: 34px;
  width: 100%;
}

.tile-54 .tile-div-canvas.default {
  top: 34px;
}

.tile-54 .tile-div-canvas.without-icon {
  top: 34px;
}

.tile-54 .tile-div-canvas.button-rocker {
  top: 34px;
}

.tile-54 .tile-div-canvas.function {
  top: 29px;
}

.tile-54 .tile-div-canvas canvas {
  flex: 1 1 auto;
}

.tile-5470 .tile-div-canvas.without-icon {
  top: 30px;
}

.tile-54 .tile-div-canvas.without-icon-preview {
  top: -4px;
}

.tile-54 .direction-column {
  height: 100%;
}

.tile-54 .tile-border {
  border: 1px dashed #bababa;
}

.tile-54 .tile-border-preview {
  border: 1px dashed transparent;
}

.tile-54 .tile-border-rocker {
  --tile-border-rocker-space-between-tiles: calc(1 * var(--tile-54-space-for-separation-line));
  width: calc((var(--tile-54-size-without-padding) - var(--tile-border-rocker-space-between-tiles)) / 2 * 1px);
  max-width: calc((var(--tile-54-size-without-padding) - var(--tile-border-rocker-space-between-tiles)) / 2 * 1px);
}

.tile-54 .tile-border-rocker-three {
  --tile-border-rocker-three-space-between-tiles: calc(2 * var(--tile-54-one-button-one-rocker-space-between-tiles));
  width: calc((var(--tile-54-size-without-padding) - var(--tile-border-rocker-three-space-between-tiles)) / 3 * 1px);
  max-width: calc(
    (var(--tile-54-size-without-padding) - var(--tile-border-rocker-three-space-between-tiles)) / 3 * 1px
  );
}

.tile-54 .rocker-padding-top {
  padding-top: 0px !important;
}

.tile-54 .box-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* height: 40px; */
}

.tile-54 .abb-border-icon-function,
.tile-54 .bje-border-icon-function {
  width: 32px;
  height: 32px;
  border-radius: 50% !important;
  border: 1px dashed #bababa;
}

.tile-54 .abb-border-icon-function-with-canvas,
.tile-54 .bje-border-icon-function-with-canvas {
  width: 32px;
  height: 32px;
  border-radius: 50% !important;
  border-top: 1px dashed #bababa;
  border-left: 1px dashed #bababa;
  border-right: 1px dashed #bababa;
  border-bottom: 1px dashed transparent;
}

.tile-54 .abb-border-icon-function-with-canvas-preview,
.tile-54 .bje-border-icon-function-with-canvas-preview {
  width: 32px;
  height: 32px;
  border-bottom: none;
}

.tile-54 .abb-border-icon-control,
.tile-54 .bje-border-icon-control {
  width: 37px;
  height: 37px;
  border-radius: 50% !important;
  border: 1px dashed #bababa;
}

.tile-54 .abb-border-icon-control-with-canvas,
.tile-54 .bje-border-icon-control-with-canvas {
  width: 34px;
  height: 34px;
  border-radius: 50% !important;
  border-top: 1px dashed #bababa;
  border-left: 1px dashed #bababa;
  border-right: 1px dashed #bababa;
  border-bottom: 1px dashed transparent;
}

.tile-54 .abb-border-icon-control-with-canvas-preview,
.tile-54 .bje-border-icon-control-with-canvas-preview {
  width: 37px;
  height: 37px;
  border-bottom: none;
}

.tile-54 .bje-border-icon-control:hover {
  border-color: #009fe3 !important;
}

.tile-54 .bje-border-icon-function:hover {
  border-color: #009fe3 !important;
}

.tile-54 .abb-border-icon-control:hover {
  border-color: #ff000f !important;
}

.tile-54 .abb-border-icon-function:hover {
  border-color: #ff000f !important;
}

.tile-54 .textarea {
  width: 100%;
  border: none;
  resize: none;
  text-align: center;
  background-color: transparent;
  line-height: 74%;
}

.tile-54 .textarea:focus {
  outline: none;
}

.tile-54 .textarea::placeholder {
  font-size: 10pt;
  padding-top: 5px;
}

.tile-54 .printable-area-four-button {
  height: calc((var(--tile-54-size-without-padding) - var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

.tile-54 .printable-area-two-buttons-one-rocker-control {
  height: calc((var(--tile-54-size-without-padding) - var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

.tile-54 .printable-area-two-buttons-one-rocker-function {
  height: calc((var(--tile-54-size-without-padding) - var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

.tile-54 .printable-area-one-button-one-rocker {
  height: calc((var(--tile-54-size-without-padding) - var(--tile-54-space-for-separation-line)) / 3 * 1px);
}

.tile-54 .printable-area-rocker {
  --space-between-tiles: calc(2 * var(--tile-54-one-rocker-space-between-tiles));
  height: calc((var(--tile-54-size-without-padding) - var(--space-between-tiles)) / 3 * 1px);
}

.tile-54 .printable-area-two-rockers {
  --space-between-tiles: calc(2 * var(--tile-54-one-rocker-space-between-tiles));
  height: calc((var(--tile-54-size-without-padding) - var(--space-between-tiles)) / 3 * 1px);
}

/* ROCKER */
.tile-54 .rocker-margin-bottom {
  margin-bottom: calc(var(--tile-54-one-rocker-space-between-tiles) / 2 * 1px);
}

.tile-54 .rocker-margin-top {
  margin-top: calc(var(--tile-54-one-rocker-space-between-tiles) / 2 * 1px);
}

/* TWO BUTTONS */
.tile-54 .two-buttons-margin-left {
  margin-left: calc((var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

.tile-54 .two-buttons-margin-right {
  margin-right: calc((var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

/* TWO ROCKERS */
.tile-54 .two-rockers-margin-bottom {
  margin-bottom: calc(var(--tile-54-two-rockers-space-between-tiles) / 2 * 1px);
}

.tile-54 .two-rockers-margin-left {
  margin-left: calc(var(--tile-54-space-for-separation-line) / 2 * 1px);
}

.tile-54 .two-rockers-margin-right {
  margin-right: calc(var(--tile-54-space-for-separation-line) / 2 * 1px);
}

.tile-54 .two-rockers-margin-top {
  margin-top: calc(var(--tile-54-two-rockers-space-between-tiles) / 2 * 1px);
}

/* ONE BUTTON ONE ROCKER */
.tile-54 .one-button-one-rocker-margin-bottom {
  margin-bottom: calc((var(--tile-54-one-button-one-rocker-space-between-tiles)) / 2 * 1px);
}

.tile-54 .one-button-one-rocker-margin-right {
  margin-right: calc(var(--tile-54-space-for-separation-line) / 2 * 1px);
}

.tile-54 .one-button-one-rocker-margin-left {
  margin-left: calc(var(--tile-54-space-for-separation-line) / 2 * 1px);
}

.tile-54 .one-button-one-rocker-margin-top {
  margin-top: calc((var(--tile-54-one-button-one-rocker-space-between-tiles)) / 2 * 1px);
}

/* TWO BUTTONS ONE ROCKER */
.tile-54 .two-buttons-one-rocker-margin-bottom {
  margin-bottom: calc((var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

.tile-54 .two-buttons-one-rocker-margin-left {
  margin-left: calc(var(--tile-54-two-buttons-one-rocker-space-between-tiles) / 2 * 1px);
}

.tile-54 .two-buttons-one-rocker-margin-right {
  margin-right: calc(var(--tile-54-two-buttons-one-rocker-space-between-tiles) / 2 * 1px);
}

.tile-54 .two-buttons-one-rocker-margin-top {
  margin-top: calc((var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

.tile-54 .two-buttons-one-rocker-margin-left-btn {
  margin-left: calc(var(--tile-54-space-for-separation-line) / 2 * 1px);
}

.tile-54 .two-buttons-one-rocker-margin-right-btn {
  margin-right: calc(var(--tile-54-space-for-separation-line) / 2 * 1px);
}

/* FOUR BUTTONS */
.tile-54 .four-buttons-margin-bottom {
  margin-bottom: calc((var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

.tile-54 .four-buttons-margin-left {
  margin-left: calc((var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

.tile-54 .four-buttons-margin-top {
  margin-top: calc((var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

.tile-54 .four-buttons-margin-right {
  margin-right: calc((var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

/* Slider */
.tile-54 .slider {
  color: #000000;
  width: 0.35mm;
  margin: 0px;
}

.tile-54 .slider-top {
  height: 47px;
}

.tile-54 .slider-top-only-icon {
  height: 47px;
}

.tile-54 .slider-top-with-icon-and-canvas {
  height: 47px;
}

.tile-54 .slider-top-only-canvas {
  height: 47px;
}
.tile-54 .tile-border-preview .slider-top-only-canvas {
  height: 47px;
  margin-bottom: 12px;
}

.tile-54 .slider-vertical-left {
  -webkit-transform: rotate(90deg);
  /* position: absolute;
  margin-left: 30px;
  margin-top: -15px; */
}

.tile-54 .slider-vertical-right {
  -webkit-transform: rotate(90deg);
  /* -webkit-transform: rotate(90deg);
  position: absolute;
  margin-left: 30px;
  margin-top: -43px; */
}

.tile-54 .slider-bottom {
  height: 84px;
}

.tile-54 .slider-bottom-only-icon {
  height: 84px;
  margin-top: 1px;
}

.tile-54 .slider-bottom-with-icon-and-canvas {
  height: 78px;
  margin-top: 13px;
}

.tile-54 .slider-bottom-only-canvas {
  height: 80px;
  margin-top: 9px;
}
.tile-54 .tile-border-preview .slider-bottom-only-canvas {
  height: 90px;
  margin-top: 21px;
}

.tile-54 .slider-two-buttons-margin-right {
  margin-right: calc((var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

.tile-54 .slider-two-buttons-margin-bottom {
  margin-bottom: calc((var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

.tile-54 .slider-two-buttons-margin-left {
  margin-left: calc((var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

.tile-54 .slider-two-buttons-margin-top {
  margin-top: calc((var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

.tile-54 .printable-area-slider-two-buttons {
  height: calc((var(--tile-54-size-without-padding) - var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

/* TWO SLIDERS */
.tile-54 .two-sliders-margin-left {
  margin-left: calc((var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

.tile-54 .two-sliders-margin-right {
  margin-right: calc((var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

/* ONE SLIDER ONE ROCKER */
.tile-54 .one-slider-one-rocker-margin-right {
  margin-right: calc(var(--tile-54-space-for-separation-line) / 2 * 1px);
}

.tile-54 .one-slider-one-rocker-margin-left {
  margin-left: calc(var(--tile-54-space-for-separation-line) / 2 * 1px);
}

.tile-54 .one-slider-one-rocker-margin-bottom {
  margin-bottom: calc(var(--tile-54-one-slider-one-rocker-space-between-tiles) / 2 * 1px);
}

.tile-54 .printable-area-one-slider-one-rocker {
  --space-between-tiles: calc(2 * var(--tile-54-one-rocker-space-between-tiles));
  height: calc((var(--tile-54-size-without-padding) - var(--space-between-tiles)) / 3 * 1px);
}

.tile-54 .one-slider-one-rocker-margin-top {
  margin-top: calc(var(--tile-54-one-slider-one-rocker-space-between-tiles) / 2 * 1px);
}

/* ONE SLIDER ONE BUTTON */
.tile-54 .one-slider-one-button-margin-right {
  margin-right: calc((var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

.tile-54 .one-slider-one-button-margin-left {
  margin-left: calc((var(--tile-54-space-for-separation-line)) / 2 * 1px);
}

.tile-62 .one-slider-one-button-margin-left {
  margin-left: calc((var(--tile-54-space-for-separation-line) - var(--tile-54-separation-line-width)) / 2 * 1px);
}
