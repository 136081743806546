.tableDimension tr th {
  height: 10px;
  padding: 0px;
  font-size: 10px;
  font-weight: bold;
}

.tableDimension tr th:first-child {
  width: 50px;
}

.tableDimension tr td {
  height: 10px;
  padding: 0px;
  font-size: 12px;
  text-align: left;
  width: 40px;
}

.tableDimension tr td:first-child {
  height: 10px;
  padding: 0px 5px 0px 0px;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
}
