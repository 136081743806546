.insertConfiguration {
  display: flex;
  justify-content: center;
}

.insertConfiguration__main {
  justify-content: center;
  align-items: center;
  width: 1328px;
}

.insertConfiguration__headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0 4px 0;
}

.insertConfiguration__headerContainerButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 891px;
}

.insertConfiguration__backToSwitchSelectionBtn {
  display: flex !important;
  align-items: center !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  text-transform: unset !important;
  line-height: 140% !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 0px !important;
  color: #1d1d1b !important;
}

.insertConfiguration__projectName {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #696969;
}

.insertConfiguration__projectName > span {
  margin-left: 4px;
  color: #000000;
}

.insertConfiguration__mainContainer {
  width: 100% !important;
  display: flex;
  justify-content: center;
}

.insertConfiguration__mainPreview--horizontal,
.insertConfiguration__mainPreview--vertical {
  position: relative;
  background: linear-gradient(316.08deg, #f5f5f5 52.92%, rgba(207, 207, 207, 0.36) 100%);
  border-radius: 4px;
  width: 891px;
  display: flex;
  align-items: center;

  overflow: hidden;
  min-width: 891px;
  flex-direction: column;
}

.insertConfiguration__mainPreview--horizontal {
  height: 100%;
  min-height: 727px;
}

.insertConfiguration__mainPreview--vertical {
  flex-direction: column;
  height: 727px;
}

.insertConfiguration__mainPreviewTitle {
  text-align: center;
  padding: 74px 0px 0px 0px;
  height: 130px;
}

.insertConfiguration__mainPreviewTitle--vertical {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.insertConfiguration__mainPreviewTitle--horizontal {
  padding: 15px 0 50px 0;
}

.insertConfiguration__mainPreviewImage--horizontal,
.insertConfiguration__mainPreviewImage--horizontal {
  text-align: center;
}

.insertConfiguration__totalImg__1--horizontal,
.insertConfiguration__totalInsertImg__1--horizontal {
  max-width: 500px;
}

.insertConfiguration__totalImg__2--horizontal,
.insertConfiguration__totalInsertImg__2--horizontal {
  max-width: 700px;
}

.insertConfiguration__totalImg__2--vertical {
  max-width: 700px;
  transform: rotate(90deg);
}

.insertConfiguration__totalImg__3--vertical {
  transform: rotate(90deg);
}

.insertConfiguration__totalImg__4--vertical {
  transform: rotate(90deg);
}

.insertConfiguration__totalImg__5--vertical {
  transform: rotate(90deg);
}

h4.insertConfiguration__mainTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  color: #1d1d1b;
  margin-block-start: 0;
  margin-block-end: 0;
}

.insertConfiguration__mainParametersDivider {
  width: 100%;
  border-top: 1px solid #bababa;
  margin: 24px 0px !important;
}

.insertConfiguration__favDesignButtonContainer {
  margin-bottom: 16px;
}

.insertConfiguration__mainParameters {
  max-width: 437px;
  width: 437px;
  padding: 0px 16px;
}

.insertConfiguration__mainParametersNextButton button {
  text-transform: unset;
  font-style: normal;
  line-height: 140%;
}

.insertConfiguration__mainParametersNextButton .btnPreviousCover:disabled {
  border-color: #cccccc !important;
  color: #cccccc !important;
}

.insertConfiguration__mainParametersNextButton .btnNextCover:disabled,
.insertConfiguration__mainParametersNextButton .btnGoToSummary:disabled {
  background-color: #cccccc !important;
  color: #ffffff !important;
}

.insertConfiguration__insertImg__carat1-1--horizontal {
  position: absolute;
  left: -4.5%;
  top: -4.6%;
  transform: scale(0.94);
}

.insertConfiguration__insertImg__carat1-2--horizontal {
  position: absolute;
  left: -19.9%;
  top: -32.8%;
  transform: scale(0.61);
}

.insertConfiguration__insertImg__carat2-2--horizontal {
  position: absolute;
  left: 19.7%;
  top: -32.8%;
  transform: scale(0.61);
}

.insertConfiguration__insertImg__carat1-3--horizontal {
  position: absolute;
  left: -35.7%;
  top: -69.4%;
  transform: scale(0.425);
}

.insertConfiguration__insertImg__carat2-3--horizontal {
  position: absolute;
  left: -1.4%;
  top: -69.4%;
  transform: scale(0.425);
}

.insertConfiguration__insertImg__carat3-3--horizontal {
  position: absolute;
  left: 32.3%;
  top: -69.4%;
  transform: scale(0.425);
}

.insertConfiguration__insertImg__carat1-4--horizontal {
  position: absolute;
  left: -29.1%;
  top: -68.9%;
  transform: scale(0.425);
}

.insertConfiguration__insertImg__carat2-4--horizontal {
  position: absolute;
  left: -1.3%;
  top: -68.9%;
  transform: scale(0.425);
}

.insertConfiguration__insertImg__carat3-4--horizontal {
  position: absolute;
  left: 26.2%;
  top: -68.9%;
  transform: scale(0.425);
}

.insertConfiguration__insertImg__carat4-4--horizontal {
  position: absolute;
  left: 53.7%;
  top: -68.9%;
  transform: scale(0.425);
}

.insertConfiguration__insertImg__carat1-4--vertical {
  position: absolute;
  left: 11.3%;
  top: -167.5%;
  transform: scale(0.425);
}

.insertConfiguration__insertImg__carat2-4--vertical {
  position: absolute;
  left: 11.3%;
  top: -101.9%;
  transform: scale(0.425);
}

.insertConfiguration__insertImg__carat3-4--vertical {
  position: absolute;
  left: 11.3%;
  top: -36.2%;
  transform: scale(0.425);
}

.insertConfiguration__insertImg__carat4-4--vertical {
  position: absolute;
  left: 11.3%;
  top: 29.1%;
  transform: scale(0.425);
}

.insertConfiguration__insertImg__carat1-2--vertical {
  position: absolute;
  left: -28.6%;
  top: -112.8%;
  transform: scale(0.385);
}

.insertConfiguration__insertImg__carat2-2--vertical {
  position: absolute;
  left: -28.6%;
  top: -47.1%;
  transform: scale(0.385);
}

.insertConfiguration__insertImg__carat1-3--vertical {
  position: absolute;
  left: -1.5%;
  top: -135%;
  transform: scale(0.423);
}

.insertConfiguration__insertImg__carat2-3--vertical {
  position: absolute;
  left: -1.5%;
  top: -68.7%;
  transform: scale(0.423);
}

.insertConfiguration__insertImg__carat3-3--vertical {
  position: absolute;
  left: -1.5%;
  top: -3.5%;
  transform: scale(0.423);
}

.insertConfiguration__insertImg__buschbalancesi1-1--horizontal {
  position: absolute;
  left: -4.5%;
  top: -4%;
  transform: scale(0.81);
}

.insertConfiguration__insertImg__buschbalancesi1-2--horizontal {
  position: absolute;
  left: -19.5%;
  top: -31.3%;
  transform: scale(0.54);
}

.insertConfiguration__insertImg__buschbalancesi2-2--horizontal {
  position: absolute;
  left: 19.5%;
  top: -31.3%;
  transform: scale(0.54);
}

.insertConfiguration__insertImg__buschbalancesi1-2--vertical {
  position: absolute;
  left: -28.5%;
  top: -109.3%;
  transform: scale(0.343);
}

.insertConfiguration__insertImg__buschbalancesi2-2--vertical {
  position: absolute;
  left: -28.5%;
  top: -46.3%;
  transform: scale(0.343);
}

.insertConfiguration__insertImg__buschbalancesi1-3--horizontal {
  position: absolute;
  left: -35.8%;
  top: -68.7%;
  transform: scale(0.368);
}

.insertConfiguration__insertImg__buschbalancesi2-3--horizontal {
  position: absolute;
  left: -3%;
  top: -68.7%;
  transform: scale(0.368);
}

.insertConfiguration__insertImg__buschbalancesi3-3--horizontal {
  position: absolute;
  left: 29.3%;
  top: -68.7%;
  transform: scale(0.368);
}

.insertConfiguration__insertImg__buschbalancesi1-3--vertical {
  position: absolute;
  left: -2.8%;
  top: -132.4%;
  transform: scale(0.368);
}

.insertConfiguration__insertImg__buschbalancesi2-3--vertical {
  position: absolute;
  left: -2.8%;
  top: -68.7%;
  transform: scale(0.368);
}

.insertConfiguration__insertImg__buschbalancesi3-3--vertical {
  position: absolute;
  left: -2.8%;
  top: -5.7%;
  transform: scale(0.368);
}

.insertConfiguration__insertImg__buschbalancesi1-4--horizontal {
  position: absolute;
  left: -29%;
  top: -68.5%;
  transform: scale(0.37);
}

.insertConfiguration__insertImg__buschbalancesi2-4--horizontal {
  position: absolute;
  left: -2.3%;
  top: -68.5%;
  transform: scale(0.37);
}

.insertConfiguration__insertImg__buschbalancesi3-4--horizontal {
  position: absolute;
  left: 24.4%;
  top: -68.5%;
  transform: scale(0.37);
}

.insertConfiguration__insertImg__buschbalancesi4-4--horizontal {
  position: absolute;
  left: 51.2%;
  top: -68.5%;
  transform: scale(0.37);
}

.insertConfiguration__insertImg__buschbalancesi1-4--vertical {
  position: absolute;
  left: 10%;
  top: -164%;
  transform: scale(0.37);
}

.insertConfiguration__insertImg__buschbalancesi2-4--vertical {
  position: absolute;
  left: 10%;
  top: -100.7%;
  transform: scale(0.37);
}

.insertConfiguration__insertImg__buschbalancesi3-4--vertical {
  position: absolute;
  left: 10%;
  top: -37.5%;
  transform: scale(0.37);
}

.insertConfiguration__insertImg__buschbalancesi4-4--vertical {
  position: absolute;
  left: 10%;
  top: 26%;
  transform: scale(0.37);
}

.insertConfiguration__insertImg__buschbalancesi1-5--horizontal {
  position: absolute;
  left: -29.2%;
  top: -69%;
  transform: scale(0.37);
}

.insertConfiguration__insertImg__buschbalancesi2-5--horizontal {
  position: absolute;
  left: -2.5%;
  top: -69%;
  transform: scale(0.37);
}

.insertConfiguration__insertImg__buschbalancesi3-5--horizontal {
  position: absolute;
  left: 24.2%;
  top: -69%;
  transform: scale(0.37);
}

.insertConfiguration__insertImg__buschbalancesi4-5--horizontal {
  position: absolute;
  left: 50.9%;
  top: -69%;
  transform: scale(0.37);
}

.insertConfiguration__insertImg__buschbalancesi5-5--horizontal {
  position: absolute;
  left: 77.6%;
  top: -69%;
  transform: scale(0.37);
}

.insertConfiguration__insertImg__buschbalancesi1-5--vertical {
  position: absolute;
  left: 20.5%;
  top: -196%;
  transform: scale(0.37);
}

.insertConfiguration__insertImg__buschbalancesi2-5--vertical {
  position: absolute;
  left: 20.5%;
  top: -132%;
  transform: scale(0.37);
}

.insertConfiguration__insertImg__buschbalancesi3-5--vertical {
  position: absolute;
  left: 20.5%;
  top: -69%;
  transform: scale(0.37);
}

.insertConfiguration__insertImg__buschbalancesi4-5--vertical {
  position: absolute;
  left: 20.5%;
  top: -5.6%;
  transform: scale(0.37);
}

.insertConfiguration__insertImg__buschbalancesi5-5--vertical {
  position: absolute;
  left: 20.5%;
  top: 57.5%;
  transform: scale(0.37);
}

.insertConfiguration__layoutSelect .MuiOutlinedInput-input {
  padding: 24px 16px !important;
}

.insertConfiguration__text {
  margin-left: 24px !important;
}

.insertConfiguration__layoutSelect .MuiSelect-select {
  display: flex;
  align-items: center;
}

.insertConfiguration__tileText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #464646;
}

.insertConfiguration__nameText {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #1d1d1b;
}

.insertConfiguration__nameText--selected {
  font-weight: 700 !important;
}

.insertConfiguration__optionsContainer {
  padding: 50px 0 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.insertConfiguration__optionsContainer--vertical {
  padding: 0px 0 15px 0px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.insertConfiguration__option--vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transform: rotate(270deg);
  z-index: 100;
}

.insertConfiguration__option--vertical > span {
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  color: #000000;
  margin-top: 5px;
}

.insertConfiguration__optionSeparator--vertical {
  border: 1px solid #868686;
  transform: rotate(90deg);
  margin: 8px 0;
  height: 24px;
}

.insertConfiguration__option {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  z-index: 100;
}

.insertConfiguration__option > span {
  margin-top: 5px;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #000000;
}

.insertConfiguration__optionSeparator {
  border: 1px solid #868686;
  margin: 0 4px;
  transform: rotate(90deg);
  width: 24px;
}

.insertConfiguration__icons .MuiOutlinedInput-notchedOutline {
  border-style: dashed;
  border-radius: 5px;
}

.insertConfiguration__icons .MuiOutlinedInput-input {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.insertConfiguration__icons .MuiOutlinedInput-input::placeholder {
  color: #464646;
  opacity: 1;
}

.insertConfiguration__iconButton:hover {
  border-color: var(--busch-blue);
}

.insertConfiguration__imgContainer__1--horizontal {
  position: relative;
  width: 100%;
  max-width: 500px;
}

.insertConfiguration__imgContainer__2--horizontal {
  position: relative;
  width: 100%;
  max-width: 700px;
  left: 21%;
}

.insertConfiguration__imgContainer__2--vertical {
  position: relative;
  width: 100%;
  max-width: 700px;
  margin-top: 45%;
  left: 50%;
  transform: translateX(-50%);
}

.insertConfiguration__imgContainer__2--horizontal {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 700px;
  top: 0%;
}

.insertConfiguration__imgContainer__3--horizontal {
  position: relative;
  width: 100%;
  max-width: 900px;
  left: 25.5%;
}

.insertConfiguration__imgContainer__3--vertical {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin-top: 67%;
  left: 50%;
  transform: translateX(-50%);
}

.insertConfiguration__imgContainer__4--horizontal {
  position: relative;
  width: 100%;
  max-width: 1100px;
  left: 27.5%;
}

.insertConfiguration__imgContainer__4--vertical {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin-top: 70%;
  left: 44%;
  transform: translateX(-50%);
}

.insertConfiguration__imgContainer__5--horizontal {
  position: relative;
  width: 100%;
  max-width: 1100px;
  left: 29.5%;
}

.insertConfiguration__imgContainer__5--vertical {
  position: relative;
  width: 100%;
  max-width: 1300px;
  margin-top: 86%;
  left: 37%;
  transform: translateX(-50%);
}

.insertConfiguration__textField1fontSize,
.insertConfiguration__textField2fontSize,
.insertConfiguration__textField3fontSize,
.insertConfiguration__textField4fontSize,
.insertConfiguration__textField5fontSize {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-align: center !important;
  color: #000000 !important;
  padding: unset !important;
  text-transform: unset !important;
}

.insertConfiguration__needHelpDiv {
  display: flex;
  justify-content: left;
  padding: 18px 0px;
}

.insertConfiguration__needHelpDivInside {
  display: flex;
  justify-content: flex-start;
}

.insertConfiguration__needHelpBtn {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 140% !important;
  text-transform: unset !important;
}

.insertConfiguration__needHelpBtn > svg {
  margin-right: 9px;
}

.insertConfiguration__mainParameterInsertColorContainer {
  width: 70%;
  margin: 24px 0 40px 0;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 16px;
}

.insertConfiguration__mainParameterInsertColorItem {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4px;
}

.insertConfiguration__icons .MuiOutlinedInput-input::placeholder {
  color: #9f9f9f !important;
}

.insertConfiguration__icons .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #9f9f9f;
}

.insertConfiguration__icons .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--busch-blue);
}

.insertConfiguration__icons button > div {
  height: 24px;
  width: 24px;
}

.insertConfiguration__previewBtn {
  position: absolute;
  right: 15px;
  bottom: 15px;
  z-index: 3;
}

.insertConfiguration__previewBtn > button {
  text-transform: unset;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #009ee2;
  padding: 10px;
}

.insertConfiguration__previewBtn > button > svg {
  margin-left: 10px;
}

.insertConfiguration__goToSummaryShortCutBtn > p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1d1d1b;
}

.insertConfiguration__goToSummaryShortCutBtn > button {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-transform: unset;
}

.insertConfiguration__optionDisabled {
  color: #bababa;
  cursor: default;
  opacity: 0.3;
}

.insertConfiguration__gridLineSwitch {
  margin-top: 8px;
  margin-left: 5px;
}

.insertConfiguration__btnUseFavoriteDesign_container {
  display: flex;
  align-items: center;
}

.insertConfiguration__btnUseFavoriteDesign {
  height: 44px;
  margin-left: 16px;
  white-space: normal;
  overflow: hidden;
  width: 100%;
}

.insertConfiguration__favoriteIcon {
  margin-right: 8px;
}

@media only screen and (max-width: 480px) {
}

/* iPads, Tablets */
@media only screen and (min-width: 480.01px) and (max-width: 768px) {
  .insertConfiguration__optionsContainer--vertical {
    left: 2%;
  }
}

/* Small screens, laptops */
@media only screen and (min-width: 768.01px) and (max-width: 1024px) {
  .insertConfiguration__optionsContainer {
    padding: 50px 0 100px;
  }

  .insertConfiguration__mainParametersNextButton button {
    font-size: 10px;
  }

  .insertConfiguration__optionsContainer--vertical {
    left: 3%;
  }
}

/* desktops */
@media only screen and (min-width: 1024.01px) and (max-width: 1200px) {
  .insertConfiguration__mainParametersNextButton button {
    font-size: 12px;
  }

  .insertConfiguration__optionsContainer--vertical {
    left: 9%;
  }
}

@media only screen and (min-width: 1200.01px) and (max-width: 1335px) {
  .insertConfiguration__mainParametersNextButton button {
    font-size: 14px;
  }

  .insertConfiguration__optionsContainer--vertical {
    left: 13%;
  }
}

@media only screen and (min-width: 1335.01px) and (max-width: 1518px) {
  .insertConfiguration__mainParametersNextButton button {
    font-size: 14px;
  }

  .insertConfiguration__optionsContainer--vertical {
    left: 17%;
  }
}

@media only screen and (min-width: 1518.01px) and (max-width: 1700px) {
  .insertConfiguration__mainParametersNextButton button {
    font-size: 14px;
  }

  .insertConfiguration__optionsContainer--vertical {
    left: 18%;
  }
}

@media only screen and (min-width: 1700.01px) and (max-width: 1920px) {
  .insertConfiguration__optionsContainer--vertical {
    left: 18%;
  }
}

@media only screen and (min-width: 1920.01px) and (max-width: 2200px) {
  .insertConfiguration__optionsContainer--vertical {
    left: 26%;
  }
}

@media only screen and (min-width: 2200.01px) and (max-width: 2560px) {
  .insertConfiguration__optionsContainer--vertical {
    left: 30%;
  }
}

@media only screen and (min-width: 2200.01px) and (max-width: 2560px) {
  .insertConfiguration__optionsContainer--vertical {
    left: 30%;
  }
}

@media only screen and (min-width: 2560.01px) and (max-width: 3200px) {
  .insertConfiguration__optionsContainer--vertical {
    left: 34%;
  }
}

@media only screen and (min-width: 3200.01px) and (max-width: 3840px) {
  .insertConfiguration__optionsContainer--vertical {
    left: 38%;
  }
}
