html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:root {
  --busch-blue: #009fe3;
  --busch-medium-blue: #4596ca;
  --busch-dark-blue: #007bb0;
  --abb-red: #ff000f;
  --abb-red-hover: #bb2924;
  --abb-black: #1f1f1f;
  --abb-white: #fff;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  list-style: none;
}

p {
  margin-block-start: 4px;
  margin-block-end: 4px;
}

a {
  text-decoration: none;
}

.MuiTextField-root {
  margin-top: 5px !important;
}

.MuiAutocomplete-popper {
  z-index: 9999;
}

::-webkit-scrollbar {
  background: white;
  border-radius: 4px;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #bababa;
  outline: 1px solid #bababa;
  border-radius: 4px;
  width: 6px;
}
