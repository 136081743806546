.projectSummary {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.projectSummary__headerGrid {
  height: 80px;
  padding: 0px 0px 9px 0px;
  margin-top: 40px;
}

.projectSummary__main {
  display: flex;
  justify-content: center;
  margin-top: 32px !important;
  padding-top: 32px !important;
}

.projectSummary__groupTitle {
  display: flex;
}

.projectSummary__title {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 32px !important;
  line-height: 40px !important;
  padding: 24px 0 32px 0;
}

.projectSummary__description {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #696969;
  margin-top: 5px !important;
}

.projectSummary__projectIcon {
  width: 35px;
  height: 35px;
  margin-top: 3px !important;
  cursor: pointer !important;
  margin-left: 16px !important;
}

.projectSummaryPreview__mainPreview {
  border-radius: 4px;
  height: 100%;
  position: relative;
  flex: 1.6;
  align-self: stretch;
  min-height: 727px;
  padding-right: 16px;
  max-width: 891px;
  min-width: 891px;
}

.projectSummary__mainSummary {
  width: 437px;
  height: 317px;
  padding: 24px 25px 21px 25px;
  background-color: #fafafa;
  border-radius: 4px;
}

.projectSummary__mainDescription {
  margin-top: 12px;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 17px !important;
}

.bje_projectSummary__container_info {
  margin-top: 24px;
  display: flex;
  width: 387px;
  height: 75px;
  border: 1px solid #1b7ac2;
  border-radius: 4px;
  background: #def4fa;
  padding: 12px 16px;
  gap: 10px !important;
  align-items: center !important;
}

.abb_projectSummary__container_info {
  margin-top: 24px;
  display: flex;
  width: 387px;
  height: 75px;
  border-radius: 4px;
  border-left: 2px solid #000;
  background: var(--primary-color-background-primary-white, #fff);
  box-shadow:
    0px 2px 8px 0px rgba(90, 90, 90, 0.1),
    0px 4px 20px 0px rgba(72, 72, 72, 0.1);
  padding: 12px 16px;
  gap: 10px !important;
  align-items: center !important;
}

.projectSummary__info {
  width: 329px;
  height: 51px;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  display: flex;
  align-items: center;
}

.projectSummary__mainSummaryDivider {
  border-top: 1px solid #dbdbdb;
  margin: 24px 0 0 0;
  width: 100%;
}

.projectSummary__mainSummary h4 {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

.projectSummary_coverSetsMessage {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.projectSummary_divider {
  height: 16px !important;
  margin: 8px 8px !important;
}

.projectSummary_OrderTitle,
.projectSummary_OrderValue,
.projectSummary_OrderTitle {
  font-size: 18px !important;
  font-weight: 400px !important;
  color: #1d1d1b !important;
}

.projectSummary_OrderSubtitle {
  font-size: 12px !important;
  font-weight: 700px !important;
  color: #9f9f9f !important;
}

.projectSummary_TotalValue {
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: right;
}

.projectPreview__mainSummaryAlert {
  height: 42px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  border: 1px solid #1b7ac2;
  background: #def4fa;
  border-radius: 4px;
  font-size: 14px;
}

.projectSummary__mainSummaryButtons {
  margin-top: 24px;
  margin-bottom: 16px;
}

.projectSummary__mainSummaryButtons > button {
  text-transform: unset !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 140% !important;
  padding: 15.5px 0 !important;
}

.projectSummary__mainSummaryButtons button:nth-child(1) {
  width: 100%;
  text-transform: unset;
  font-style: normal !important;
  line-height: 140% !important;
}

.projectSummary__mainSummaryButtons .disabled {
  background-color: #bababa !important;
}

.projectSummary__mainSummaryReviewMessagem {
  font-size: 14px !important;
  font-weight: 400 !important;
  text-align: center;
}

/* TABLE */

.projectSummary__table {
  width: 891px;
  margin-top: 37px;
  overflow: hidden;
}

.projectSummary__thead {
  width: 891px;
}

.projectSummary__thead_preview {
  width: 168px;
}

.projectSummary__thead_details {
  width: 242px;
}

.projectSummary__thead_quantity {
  width: 183px;
}

.projectSummary__thead_price {
  width: 150px;
}

.projectSummary__thead_status {
  width: 82px;
}

.projectSummary__thead_dots {
  width: 80px;
}

.projectSummaryTable_headercell {
  color: var(--primary-color-grey-70, #464646) !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 120% !important;
  /* 14.4px */
  border-bottom: 1px solid #dbdbdb !important;
}

.projectSummaryTable_cell {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #696969 !important;
  border-bottom: 1px solid #dbdbdb !important;
  padding: 16px !important;
}

.projectSummaryTable_row {
  height: 130px;
  padding: 16px 0px;
}

.projectSummaryTable_cell_container {
  max-width: 240px;
}

.projectSummaryTable_cell_cover {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
}

.projectSummaryTable_cell_designrange {
  margin-top: 4px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1d1d1b;
}

.projectSummaryTable_cell_designrange .detail {
  margin-left: 4px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: inherit !important;
  color: #696969;
}

.projectSummaryTable_cell_numbercovers {
  margin-top: 4px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #1d1d1b;
}

.projectSummaryTable_cell_seedetails {
  text-transform: unset !important;
  padding: 0px !important;
}

.projectSummaryTable_cell_numbercovers .detail {
  margin-left: 4px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: inherit !important;
  color: #696969;
}

.projectSummaryTable_cell_iconquantity {
  padding: 0px !important;
}

.projectSummaryTable_cell_quantity_bje {
  padding: 0 !important;
  margin: 0 5px !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 4px !important;
  width: 42px !important;
  height: 42px !important;
}

.projectSummaryTable_cell_quantity_bje_draft {
  padding: 0px !important;
  margin: 0 5px !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 4px !important;
  background-color: #ebebeb !important;
  width: 42px !important;
  height: 42px !important;
}

.projectSummaryTable_cell_bje_draft {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #696969 !important;
  border-bottom: 1px solid #dbdbdb !important;
  padding: 11px !important;
}

.projectSummaryTable_cell_completed {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #696969 !important;
  border-bottom: 1px solid #dbdbdb !important;
  padding: 8px !important;
}

.projectSummaryTable__abb_table_head {
  height: 49px;
  background: var(--primary-color-background-secondary-white, #f5f5f5);
}

.summaryTable_Cell_text.details {
  color: var(--primary-color-brand-primary-black, #000) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 120% !important;
  border-bottom: 1px solid #dbdbdb !important;
}
.summaryTable_Cell_text {
  color: var(--primary-color-brand-primary-black, #000) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 120% !important;
  border-bottom: 1px solid #dbdbdb !important;
}

.thumb {
  width: 85px;
  height: 66px;
  flex-shrink: 0;
}
