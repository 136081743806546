.swiper {
  width: 100%;
  height: 271px;
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 1), rgba(0, 0, 0, 0));
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  cursor: pointer;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .swiper-slide:not(.swiper-slide-active):not(.swiper-slide-next):not(.swiper-slide-prev) {
    opacity: 0.2;
} */

.productCarousel__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.swiper-slide .productCarousel__container > img {
  transform: scale(0.9);
  user-select: none;
}

.swiper-slide-active .productCarousel__container > img {
  transform: scale(1.875);
  user-select: none;
}

.swiper-slide-active .productCarousel__container .frame-content-1-fold {
  /* min-width: auto !important;
  width: auto !important;
  height: auto !important;   */
}

.swiper-slide-active .productCarousel__container .frame-container {
  justify-content: center;
  height: 440px !important;
  margin-top: -70px;
}

.productCarousel__container {
  /* width: 100% !important; */
}

.productCarousel__container .frame-container {
  cursor: pointer;
  transition: 0.2s all;
}

.swiper-slide-active {
  /* margin-right: 132px; */
  /* width: 428px !important; */
}

.frame-content-1-fold {
  /* min-width: unset; */
}

/* .swiper-slide-active .productCarousel__container>img.insertImage {
    max-width: 500px !important;
}

.swiper-slide-active .productCarousel__container>img.insertImage__solo {

    margin-top: 0;
    margin-left: 0;
    transform: scale(1.1) !important;
} */

.productCarousel__container > img.insertImage__solo {
  top: -0.5%;
  margin-left: 3.4px;
  transform: scale(1);
}

.swiper-slide-active .productCarousel__container > img.insertImage__future {
  top: 0.6%;
  left: 0.6%;
  margin-top: 0;
  margin-left: 0;
  transform: scale(1.28) !important;
}

.productCarousel__container > img.insertImage__future {
  margin-top: 0px;
  margin-left: 2px;
  transform: scale(1.18);
}

.swiper-slide-active .productCarousel__container > img.insertImage__purestainlesssteel {
  top: 0%;
  left: 0%;
  margin-top: 0;
  margin-left: 0;
  transform: scale(1.285) !important;
}

.productCarousel__container > img.insertImage__purestainlesssteel {
  margin-top: 0px;
  margin-left: 1px;
  transform: scale(1.19);
}

.swiper-slide-active .productCarousel__container > img.insertImage__futurelinear {
  top: 0.3%;
  left: 2.8%;
  margin-top: 0;
  margin-left: 0;
  transform: scale(1.25) !important;
}

.productCarousel__container > img.insertImage__futurelinear {
  margin-left: 8px;
  transform: scale(1.14);
}

.swiper-slide-active .productCarousel__container > img.insertImage__carat {
  left: 0%;
  transform: scale(1.14) !important;
}

.productCarousel__container > img.insertImage__carat {
  transform: scale(1.03);
}

.swiper-slide-active .productCarousel__container > img.insertImage__axcentpur {
  left: 0%;
  transform: scale(0.925) !important;
}

.productCarousel__container > img.insertImage__axcentpur {
  transform: scale(0.85);
}

.swiper-slide-active .productCarousel__container > img.insertImage__axcent {
  left: 0%;
  transform: scale(0.915) !important;
}

.productCarousel__container > img.insertImage__axcent {
  transform: scale(0.85);
}

.swiper-slide-active .productCarousel__container > img.insertImage__buschbalancesi {
  left: 0%;
  transform: scale(0.97) !important;
}

.productCarousel__container > img.insertImage__buschbalancesi {
  transform: scale(0.89);
}

.productCarousel__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.productCarousel__container > img {
  width: 100%;
  max-width: 145px;
  transition: transform 0.4s;
}

.productCarousel__container .insertImage {
  z-index: 1;
  position: absolute;
}

.productCarousel__container > p {
  font-weight: 400;
  font-size: 16px;
}

.swiper-slide-active .productCarousel__container > p {
  padding-top: 15px;
}

.swiper-pagination {
  display: none;
}

.productCarousel__selected {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -40px;
}

.productCarousel__selected > p {
  font-family: "ABBvoice";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #1d1d1b;
  margin-block-start: 0;
  margin-block-end: 0;
}

.productCarousel__selected > h3 {
  font-family: "ABBvoice";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  color: #1d1d1b;
  margin-block-start: 0;
  margin-block-end: 0;
  white-space: nowrap;
}

.productCarousel__notSelected > p {
  margin-top: -370px;
  margin-left: -80px;
}

/* Mobile devices */
@media only screen and (max-width: 480px) {
  .swiper-slide-active .productCarousel__container > img.insertImage__carat {
    left: auto;
  }

  .swiper-slide-active .productCarousel__container > img.insertImage__future {
    left: auto;
  }

  .swiper-slide-active .productCarousel__container > img.insertImage__purestainlesssteel {
    left: auto;
  }

  .swiper-slide-active .productCarousel__container > img.insertImage__solo {
    left: auto;
  }

  .swiper-slide-active .productCarousel__container > img.insertImage__axcentpur {
    left: auto;
  }

  .swiper-slide-active .productCarousel__container > img.insertImage__axcent {
    left: auto;
  }

  .swiper-slide-active .productCarousel__container > img.insertImage__futurelinear {
    left: auto;
  }

  .swiper-slide-active .productCarousel__container > img.insertImage__buschbalancesi {
    left: auto;
  }

  .slide__leftArrow {
    left: 0;
  }

  .slide__rightArrow {
    right: 0;
  }
}

/* iPads, Tablets */
@media only screen and (min-width: 480.01px) and (max-width: 768px) {
  .swiper-slide-active .productCarousel__container > img.insertImage__carat {
    left: auto;
  }

  .swiper-slide-active .productCarousel__container > img.insertImage__future {
    left: auto;
  }

  .swiper-slide-active .productCarousel__container > img.insertImage__purestainlesssteel {
    left: auto;
  }

  .swiper-slide-active .productCarousel__container > img.insertImage__solo {
    left: auto;
  }

  .swiper-slide-active .productCarousel__container > img.insertImage__axcentpur {
    left: auto;
  }

  .swiper-slide-active .productCarousel__container > img.insertImage__axcent {
    left: auto;
  }

  .swiper-slide-active .productCarousel__container > img.insertImage__futurelinear {
    left: auto;
  }

  .swiper-slide-active .productCarousel__container > img.insertImage__buschbalancesi {
    left: auto;
  }
}
