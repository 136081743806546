.checkoutDialogContent__main p {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #000000 !important;
}

.checkoutDialogContent__main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkout__btnContainer {
  display: flex;
  flex-direction: column;
  margin: 40px 0 20px 0;
  max-width: 70%;
}

.checkout__btnContainer .checkout__payOneBtn {
  width: 100%;
  margin-bottom: 24px;
  background-color: #4596ca;
  padding: 12px 16px !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.checkout__btnContainer .checkout__distributorBtn {
  width: 100%;
  font-style: normal;
  padding: 11px 15px !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
