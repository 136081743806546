.dialog-container {
  width: 394px;
  height: 330px;
  padding: 32px 24px !important;
  gap: 24px;
}

.dialog-title-container {
  padding: 0px !important;
}

.dialog-content-container {
  padding: 0px !important;
}

.dialog-title {
  font-size: 24px;
  list-style: 28px;
}

.dialog-form-container {
  gap: 24px;
}

.form__label {
  line-height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #0a0a0a;
  margin-bottom: 4px;
}

.dialog_input {
  border: 1px solid #e0e0e0;
  border-radius: 2px;
}

.dialog__actions {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.dialog__actions button {
  text-transform: capitalize !important;
}

.dialog-confirm-button {
  width: 120px;
  height: 34px;
}
