.design-preview-dialog-main {
  width: 438px;
  height: 247px;
  padding: 32px 24px !important;
}

.design-preview-dialog-body {
  height: 133px;
}

.design-preview-dialog-label {
  font-family: "ABBVoice";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.design-preview-dialog-error {
  color: #d32f2f;
  font-size: 12px;
  font-weight: 400;
}

.design-preview-dialog-btn-save {
  width: 193px;
  height: 41px;
  text-transform: none !important;
}

.design-preview-dialog-btn-cancel {
  width: 180px;
  height: 41px;
  text-transform: none !important;
}

.editDialog__editIcon {
  width: 35px;
  height: 35px;
}
