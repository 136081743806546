.divFrameBackground-carat {
  width: 300px;
  height: 300px;
  margin: 4px 3px 0px 3px;
  background-position: center;
  background-size: 470px;
}

.frame-cover {
  /* width: 252px;
  min-width: 252px;
  height: 252px;
  margin: 0px 0px 0px 0px; */
  background-position: center;
  background-size: 252px;
  background-repeat: no-repeat;
}

.rocker-rotated-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  z-index: -1;
  transform: rotate(90deg);
}

/* .frame-cover-0 {
  margin: 12px 3px 0px 12px;
}

.frame-cover-1 {
  margin: 12px 0px 0px 30px;
}

.frame-cover-2 {
  margin: 12px 0px 0px 30px;
}

.frame-cover-3 {
  margin: 12px 0px 0px 32px;
}

.frame-cover-4 {
  margin: 12px 0px 0px 32px;
} */

.frame-cover-ivory {
  background-image: url(https://ftsimagesprod.blob.core.windows.net/images/inserts/ivory/img.png);
}

.divFrameCover-future {
  width: 300px;
  min-width: 300px;
  height: 300px;
  margin: 3px;
  background-image: url(https://ftsimagesprod.blob.core.windows.net/images/inserts/blackmatt/img.png);
  background-position: center;
  background-size: 459px;
}

.divFrameCover-calle {
  width: 300px;
  min-width: 300px;
  height: 300px;
  margin: 3px;
  background-image: url(https://ftsimagesprod.blob.core.windows.net/images/inserts/blackmatt/img.png);
  background-position: center;
  background-size: 459px;
}
