.notSupported {
  margin: auto;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.notSupported__image {
  width: 80%;
}

h1.notSupported__title {
  font-family: "ABBvoice";
  font-weight: 500;
  font-size: 1.8rem;
  margin-top: -5px;
  margin-bottom: -5px;
}

p.notSupported__description {
  font-size: 1.1rem;
  line-height: 1.7;
}

p.notSupported__description--extraMargin {
  margin: 35px 0;
}

.notSupported__browserContainer {
  display: flex;
  align-items: center;
}

.browserLogo {
  width: 90px;
}

p.notSupported__description > a {
  color: var(--busch-blue);
  text-decoration: underline;
  padding-left: 10px;
}

.notSupported__loadBtn {
  font-family: "ABBvoice";
  font-weight: 500;
  text-decoration: none;
  background-color: var(--busch-blue);
  color: #fff;
  border-radius: 30px;
  padding: 7px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: background-color 0.4s;
}

.notSupported__loadBtn > svg {
  margin-right: 5px;
}

.notSupported__loadBtn:hover {
  background-color: var(--busch-dark-blue);
}
