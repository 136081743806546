.terms-and-condition-dialog-title {
  font-size: 24px !important;
  line-height: 30px !important;
  font-weight: 700 !important;
}

.terms-and-condition-dialog-body {
  border: 1px solid #dbdbdb;
  width: 616px;
  height: 430px;
  margin-top: 10px;
}

.terms-and-condition-dialog-body-content {
  padding: 10px;
  height: 430px;
  overflow: auto;
}

.terms-and-condition-accept-button {
  width: 193px;
  height: 41px;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}

.terms-and-condition-back-button {
  width: 225px;
  height: 33px;
  text-transform: capitalize !important;
  font-weight: 400 !important;
}
