.dialogcontent__main {
  display: flex;
  flex-direction: column;
}

.dialogcontent__body {
  width: 100%;
}

.dialogcontent__body > h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 27px;
  color: #000000;
  margin-block-start: 0;
  margin-block-end: 0;
}

.body__formControl {
  width: 100%;
}

.body__formControl label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #0a0a0a;
}

.body__formControlContainer {
  margin-top: 22px;
  margin-bottom: 22px;
}

.body__formControlInput {
  margin: 5px 0 20px 0;
  padding: 10px 16px;
  outline: none;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  transition: 0.4 border-color;
  font-size: 14px;
  transition: 0.4s border-color;
}

.body__formControlInput::placeholder {
  font-size: 15px;
  color: #9f9f9f;
}

.body__formControlInput:focus {
  border-color: var(--busch-medium-blue);
}

.body__formControlContainer .select__list {
  width: 100%;
  padding: 11px 16px;
  margin-top: 5px;
  border: 1px solid #9f9f9f;
  cursor: pointer;
  border-radius: 0;
  outline: 0;
  font-size: 15px;
  color: #9f9f9f;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: 0.4s border-color;
}

.body__formControlContainer .select__list:focus {
  border-color: var(--busch-medium-blue);
}

.body__formControlContainer .select__list:required:invalid {
  font-size: 15px;
  color: #9f9f9f;
}

.body__formControlContainer .select__list option[value=""][disabled] {
  display: none;
}

.select__list option:not(:first-of-type) {
  font-size: 15px !important;
  color: #010101 !important;
}

.dialogcontent__footer {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  column-gap: 16px;
}

.dialogcontent__footer > button {
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  max-width: 136px;
  background-color: var(--busch-medium-blue) !important;
}

.dialogcontent__footer > a {
  letter-spacing: 0.5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--busch-blue);
}

/* Mobile devices */
@media only screen and (max-width: 480px) {
  .dialogcontent__main {
    padding: 15px 20px;
  }

  .dialogcontent__body > h2 {
    font-size: 20px;
  }

  .dialogcontent__body > p {
    font-size: 14px;
  }

  .body__formControlContainer .MuiFormControlLabel-label {
    font-size: 0.8rem;
  }

  .body__formControlInput {
    padding: 7px 14px;
    font-size: 14px;
  }

  .body__formControlInput::placeholder {
    font-size: 14px;
  }

  .body__formControlContainer .select__list {
    padding: 8px 14px;
    font-size: 14px;
  }

  .dialogcontent__footer > button {
    margin-bottom: 28px;
  }
}

/* iPads, Tablets */
@media only screen and (min-width: 480.01px) and (max-width: 768px) {
}
