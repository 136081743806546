.demo {
  flex: 1.2;
  display: flex;
  background-color: #ffffff;
  border-radius: 4px;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  box-shadow:
    0px 0px 1px rgba(15, 15, 15, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);

  width: 777px;
  min-width: 777px;
  max-width: 777px;
  height: 283px;
  min-height: 283px;
  max-height: 283px;
}

.demo__imgContainer {
  background-size: cover;
  background-position: center;
  min-width: 350px;
  width: 395px;
}

.demo__textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 40px;
  flex: 1;
}

.demo__textDivider {
  margin-top: 12px;
  height: 2px;
  width: 43px;
  background-color: #009fe3;
}

.demo__textContainer > h2 {
  margin: 12px 0;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  color: #000000;
}

.demo__create {
  display: flex;
  flex-direction: column;
}

.demo__createText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 116%;
  letter-spacing: -0.7px;
  color: #696969;
  margin: 24px 0 24px 0;
}

.demo__createBtn {
  margin-top: 10px;
  display: inline-block;
  width: 49.19px;
}

.demo__createBtn > button {
  text-transform: capitalize;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
}

.demo__createBtn > button > svg {
  margin-left: 11px;
}

@media (max-width: 1360px) {
  .demo {
    width: 600px;
    min-width: 600px;
    max-width: 600px;
  }
}
