.productLines {
  background-color: #fff;
  border-radius: 3px;
  position: relative;
  min-height: 84vh;
  max-width: 1838px;
}

.productLines__backToDashboardLink > button,
.productLines__SwitchViewButton {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #1d1d1b !important;
  padding-top: 2px;
  padding-bottom: 2px;
  text-transform: none;
}

.productLines__backToDashboardLink > button > svg {
  margin-right: 12px;
}

.productLines__textBtn {
  display: flex;
  align-items: center;
}

.productLines__textBtn > svg {
  fill: #1d1d1b;
}

.productLines__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2% 0 0 0;
}

.productLines__content--grid {
  padding-top: 2% !important;
}

.content__header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.productLines__list {
  padding: 10px 0 20px 0;
  width: 100%;
}

.productLines__selectBtn {
  margin: 10px 0 20px 0;
}

.productLines__selectBtn--grid {
  padding: 2% 0 9.33% 0;
}

.productLines__selectBtn > button {
  font-family: "ABBvoice";
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.productLines__selectBtn > button > svg {
  margin-left: 8px;
  fill: var(--busch-blue);
}

.productLines__topLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.carouselView {
  position: relative;
}

.slide__leftArrow,
.slide__rightArrow {
  position: absolute;
  cursor: pointer;
  z-index: 9;
  top: 50%;
  transform: translateY(-50%);
  /* pointer-events: none; */
  padding: 20px 10px;
  font-size: 64px !important;
}

.slide__leftArrow {
  left: 36%;
}

.slide__rightArrow {
  right: 36%;
}

/* Mobile devices */
@media only screen and (max-width: 480px) {
  .productLines {
    margin-top: -10px;
  }

  .productLines__content {
    padding: 0;
  }

  .content__colorOverview {
    margin-bottom: 10px;
  }

  .productLines__selectBtn {
    bottom: 60px;
  }
}

/* iPads, Tablets */
@media only screen and (min-width: 480.01px) and (max-width: 768px) {
  .productLines__content {
    padding: 3% 0 0 0;
  }
}

/* Small screens, laptops */
@media only screen and (min-width: 768.01px) and (max-width: 1024px) {
  .content__header {
    margin-left: 18px;
  }
}

/* Desktop*/
@media only screen and (min-width: 1024.01px) and (max-width: 1200px) {
  .content__header {
    margin-left: -43px;
  }
}
