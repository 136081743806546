.textarea-wrapper {
  position: relative;
}

.div-textarea-highlight {
  width: 100%;
  min-height: 60px;
  max-height: 66px;
  top: 177px;
  margin-top: -65px;
  font-size: 14px;
  line-height: 14px;
  padding: 10.5px;
  color: transparent;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-family: inherit;
  height: auto;
  box-sizing: border-box;
}

.div-textarea-highlight mark {
  line-height: 14px;
  background-color: #f8ecac;
  color: transparent;
}

.textarea {
  font-size: 14px;
  line-height: 14px;
  width: 100%;
  resize: none;
  min-height: 60px;
  max-height: 66px;
  border: 1px solid darkgrey;
  padding: 10px;
  margin: 0;
  overflow: hidden;
  background-color: transparent;
  position: relative;
  z-index: 1;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-family: inherit;
  height: auto;
  box-sizing: border-box;
}
