:root {
  --tile-55-size: 207.87401;
  --tile-55-padding: 12;
  --tile-55-size-without-padding: calc(var(--tile-55-size) - (2 * var(--tile-55-padding)));
  --tile-55-separation-line-width: 1;
  --tile-55-space-for-separation-line: 2;
  --tile-55-one-rocker-space-between-tiles: 1;
  --tile-55-two-rockers-space-between-tiles: 1;
  --tile-55-one-button-one-rocker-space-between-tiles: 1;
  --tile-55-two-buttons-one-rocker-space-between-tiles: 1;
  --tile-55-one-slider-one-rocker-space-between-tiles: 1;
  --tile-55-slider-two-buttons-space-between-tiles: 4;
}

.tile-55 {
  height: calc(var(--tile-55-size) * 1px);
  padding: calc(var(--tile-55-padding) * 1px);
}

.tile-55 .container {
  --space-between-tiles: calc(1 * var(--tile-55-space-for-separation-line));
  width: calc((var(--tile-55-size-without-padding) - var(--space-between-tiles)) / 2 * 1px);
  max-width: calc((var(--tile-55-size-without-padding) - var(--space-between-tiles)) / 2 * 1px);
}

.tile-55 .tile-div-canvas {
  flex: 1 1 auto;
  display: flex;
  margin-top: 3px;
  flex-direction: column;
  position: absolute;
  width: 100%;
}

.tile-55 .tile-div-canvas.default {
  top: 31px;
}

.tile-55 .tile-div-canvas.without-icon {
  top: -10px;
}

.tile-55 .tile-div-canvas.button-rocker {
  top: 31px;
}

.tile-55 .tile-div-canvas.function {
  top: 25px;
}

.tile-55 .tile-div-canvas-preview-no-icon {
  display: flex;
}

.tile-55 .tile-div-canvas canvas {
  flex: 1 1 auto;
}

.tile-55 .tile-div-canvas.without-icon {
  top: 31px;
}

.tile-55 .tile-div-canvas.without-icon-preview {
  top: -4px;
}

.tile-55 .direction-column {
  height: 100%;
}

.tile-55 .tile-border {
  border: 1px dashed #bababa;
}

.tile-55 .tile-border-preview {
  border: 1px dashed transparent;
}

.tile-55 .tile-border-rocker {
  --tile-border-rocker-space-between-tiles: calc(1 * var(--tile-55-space-for-separation-line));
  width: calc((var(--tile-55-size-without-padding) - var(--tile-border-rocker-space-between-tiles)) / 2 * 1px);
  max-width: calc((var(--tile-55-size-without-padding) - var(--tile-border-rocker-space-between-tiles)) / 2 * 1px);
}

.tile-55 .tile-border-rocker-three {
  --space-between-tiles: calc(2 * var(--tile-55-one-button-one-rocker-space-between-tiles));
  width: calc((var(--tile-55-size-without-padding) - var(--space-between-tiles)) / 3 * 1px);
  max-width: calc((var(--tile-55-size-without-padding) - var(--space-between-tiles)) / 3 * 1px);
}

.tile-55 .rocker-padding-top {
  padding-top: 0px !important;
}

.tile-55 .box-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* height: 40px; */
}

.tile-55 .abb-border-icon-function,
.tile-55 .bje-border-icon-function {
  width: 32px;
  height: 32px;
  border: 1px dashed #9f9f9f !important;
  border-radius: 50% !important;
}

.tile-55 .abb-border-icon-function-with-canvas,
.tile-55 .bje-border-icon-function-with-canvas {
  width: 32px;
  height: 32px;
  border-radius: 50% !important;
  border-top: 1px dashed #bababa;
  border-left: 1px dashed #bababa;
  border-right: 1px dashed #bababa;
  border-bottom: 1px dashed transparent;
}

.tile-55 .abb-border-icon-function-with-canvas-preview,
.tile-55 .bje-border-icon-function-with-canvas-preview {
  width: 32px;
  height: 32px;
  border-bottom: none;
}

.tile-55 .abb-border-icon-control,
.tile-55 .bje-border-icon-control {
  width: 37px;
  height: 37px;
  border: 1px dashed #9f9f9f !important;
  border-radius: 50% !important;
  /* margin-bottom: 2px; */
}

.tile-55 .abb-border-icon-control-with-canvas,
.tile-55 .bje-border-icon-control-with-canvas {
  width: 34px;
  height: 34px;
  border-radius: 50% !important;
  border-top: 1px dashed #bababa;
  border-left: 1px dashed #bababa;
  border-right: 1px dashed #bababa;
  border-bottom: 1px dashed transparent;
}

.tile-55 .abb-border-icon-control-with-canvas-preview,
.tile-55 .bje-border-icon-control-with-canvas-preview {
  width: 37px;
  height: 37px;
  border-bottom: none;
}

.tile-55 .bje-border-icon-control:hover {
  border-color: #009fe3 !important;
}

.tile-55 .bje-border-icon-function:hover {
  border-color: #009fe3 !important;
}

.tile-55 .abb-border-icon-control:hover {
  border-color: #ff000f !important;
}

.tile-55 .abb-border-icon-function:hover {
  border-color: #ff000f !important;
}

.tile-55 .textarea {
  width: 100%;
  border: none;
  resize: none;
  text-align: center;
  background-color: transparent;
  line-height: 74%;
}

.tile-55 .textarea:focus {
  outline: none;
}

.tile-55 .textarea::placeholder {
  font-size: 10pt;
  padding-top: 5px;
}

.tile-55 .printable-area-four-button {
  height: calc((var(--tile-55-size-without-padding) - var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

.tile-55 .printable-area-two-buttons-one-rocker-control {
  height: calc((var(--tile-55-size-without-padding) - var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

.tile-55 .printable-area-two-buttons-one-rocker-function {
  height: calc((var(--tile-55-size-without-padding) - var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

.tile-55 .printable-area-one-button-one-rocker {
  height: calc((var(--tile-55-size-without-padding) - var(--tile-55-space-for-separation-line)) / 3 * 1px);
}

.tile-55 .printable-area-rocker {
  --space-between-tiles: calc(2 * var(--tile-55-one-rocker-space-between-tiles));
  height: calc((var(--tile-55-size-without-padding) - var(--space-between-tiles)) / 3 * 1px);
}

.tile-55 .printable-area-two-rockers {
  --space-between-tiles: calc(2 * var(--tile-55-one-rocker-space-between-tiles));
  height: calc((var(--tile-55-size-without-padding) - var(--space-between-tiles)) / 3 * 1px);
}

/* ROCKER */
.tile-55 .rocker-margin-bottom {
  margin-bottom: calc(var(--tile-55-one-rocker-space-between-tiles) / 2 * 1px);
}

.tile-55 .rocker-margin-top {
  margin-top: calc(var(--tile-55-one-rocker-space-between-tiles) / 2 * 1px);
}

/* TWO BUTTONS */
.tile-55 .two-buttons-margin-left {
  margin-left: calc((var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

.tile-55 .two-buttons-margin-right {
  margin-right: calc((var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

/* TWO ROCKERS */
.tile-55 .two-rockers-margin-bottom {
  margin-bottom: calc(var(--tile-55-two-rockers-space-between-tiles) / 2 * 1px);
}

.tile-55 .two-rockers-margin-left {
  margin-left: calc(var(--tile-55-space-for-separation-line) / 2 * 1px);
}

.tile-55 .two-rockers-margin-right {
  margin-right: calc(var(--tile-55-space-for-separation-line) / 2 * 1px);
}

.tile-55 .two-rockers-margin-top {
  margin-top: calc(var(--tile-55-two-rockers-space-between-tiles) / 2 * 1px);
}

/* ONE BUTTON ONE ROCKER */
.tile-55 .one-button-one-rocker-margin-bottom {
  margin-bottom: calc((var(--tile-55-one-button-one-rocker-space-between-tiles)) / 2 * 1px);
}

.tile-55 .one-button-one-rocker-margin-right {
  margin-right: calc(var(--tile-55-space-for-separation-line) / 2 * 1px);
}

.tile-55 .one-button-one-rocker-margin-left {
  margin-left: calc(var(--tile-55-space-for-separation-line) / 2 * 1px);
}

.tile-55 .one-button-one-rocker-margin-top {
  margin-top: calc((var(--tile-55-one-button-one-rocker-space-between-tiles)) / 2 * 1px);
}

/* TWO BUTTONS ONE ROCKER */
.tile-55 .two-buttons-one-rocker-margin-bottom {
  margin-bottom: calc((var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

.tile-55 .two-buttons-one-rocker-margin-left {
  margin-left: calc(var(--tile-55-two-buttons-one-rocker-space-between-tiles) / 2 * 1px);
}

.tile-55 .two-buttons-one-rocker-margin-right {
  margin-right: calc(var(--tile-55-two-buttons-one-rocker-space-between-tiles) / 2 * 1px);
}

.tile-55 .two-buttons-one-rocker-margin-top {
  margin-top: calc((var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

.tile-55 .two-buttons-one-rocker-margin-left-btn {
  margin-left: calc(var(--tile-55-space-for-separation-line) / 2 * 1px);
}

.tile-55 .two-buttons-one-rocker-margin-right-btn {
  margin-right: calc(var(--tile-55-space-for-separation-line) / 2 * 1px);
}

/* FOUR BUTTONS */
.tile-55 .four-buttons-margin-bottom {
  margin-bottom: calc((var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

.tile-55 .four-buttons-margin-left {
  margin-left: calc((var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

.tile-55 .four-buttons-margin-top {
  margin-top: calc((var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

.tile-55 .four-buttons-margin-right {
  margin-right: calc((var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

/* Slider */
.tile-55 .slider {
  color: #000000;
  width: 0.35mm;
  margin: 0px;
}

.tile-55 .slider-top {
  height: 47px;
}

.tile-55 .slider-top-only-icon {
  height: 47px;
}

.tile-55 .slider-top-with-icon-and-canvas {
  height: 47px;
  margin-bottom: -2px;
}
.tile-55 .tile-border-preview .slider-top-with-icon-and-canvas {
   height: 47px;
   margin-bottom: -2px;
}

.tile-55 .slider-top-only-canvas {
  height: 47px;
  margin-bottom: 1px;
}.tile-55 .tile-border-preview .slider-top-only-canvas {
   height: 47px;
   margin-bottom: 10px;
}


.tile-55 .slider-vertical-left {
  -webkit-transform: rotate(90deg);
  /* position: absolute;
  margin-left: 30px;
  margin-top: -15px; */
}

.tile-55 .slider-vertical-right {
  -webkit-transform: rotate(90deg);
  /* -webkit-transform: rotate(90deg);
  position: absolute;
  margin-left: 30px;
  margin-top: -43px; */
}

.tile-55 .slider-bottom {
  height: 93px;
}

.tile-55 .slider-bottom-only-icon {
  height: 93px;
}

.tile-55 .slider-bottom-with-icon-and-canvas {
  height: 85px;
  margin-top: 12px;
}
.tile-55 .tile-border-preview .slider-bottom-with-icon-and-canvas {
  height: 88px;
  margin-top: 11px;
}

.tile-55 .slider-bottom-only-canvas {
  height: 85px;
  margin-top: 12px;
}.tile-55 .tile-border-preview .slider-bottom-only-canvas {
   height: 90px;
   margin-top: 22px;
}

.tile-55 .slider-two-buttons-margin-right {
  margin-right: calc((var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

.tile-55 .slider-two-buttons-margin-bottom {
  margin-bottom: calc((var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

.tile-55 .slider-two-buttons-margin-left {
  margin-left: calc((var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

.tile-55 .slider-two-buttons-margin-top {
  margin-top: calc((var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

.tile-55 .printable-area-slider-two-buttons {
  height: calc((var(--tile-55-size-without-padding) - var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

/* TWO SLIDERS */
.tile-55 .two-sliders-margin-left {
  margin-left: calc((var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

.tile-55 .two-sliders-margin-right {
  margin-right: calc((var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

/* ONE SLIDER ONE ROCKER */
.tile-55 .one-slider-one-rocker-margin-right {
  margin-right: calc((var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

.tile-55 .one-slider-one-rocker-margin-left {
  margin-left: calc(var(--tile-55-space-for-separation-line) / 2 * 1px);
}

.tile-55 .one-slider-one-rocker-margin-bottom {
  margin-bottom: calc(var(--tile-55-one-slider-one-rocker-space-between-tiles) / 2 * 1px);
}

.tile-55 .printable-area-one-slider-one-rocker {
  --space-between-tiles: calc(2 * var(--tile-55-one-rocker-space-between-tiles));
  height: calc((var(--tile-55-size-without-padding) - var(--space-between-tiles)) / 3 * 1px);
}

.tile-55 .one-slider-one-rocker-margin-top {
  margin-top: calc(var(--tile-55-one-slider-one-rocker-space-between-tiles) / 2 * 1px);
}

/* ONE SLIDER ONE BUTTON */
.tile-55 .one-slider-one-button-margin-right {
  margin-right: calc((var(--tile-55-space-for-separation-line)) / 2 * 1px);
}

.tile-55 .one-slider-one-button-margin-left {
  margin-left: calc((var(--tile-55-space-for-separation-line)) / 2 * 1px);
}
