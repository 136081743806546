.navbar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(15, 15, 15, 0.08);
  position: relative;
}

.navbar__logoTitleContainer {
  display: flex;
  align-items: center;
  padding-left: 35px;
}

.navbar__logo {
  margin-top: 7px;
  margin-left: 10px;
  height: 54px;
  display: flex;
  align-items: center;
}

.navbar__logo > a > img.bje {
  height: 53px;
}

.navbar__logo > a > img.abb {
  height: 30px;
  margin-right: 16px;
}

.navbar__title {
  display: flex;
  align-items: center;
}

.navbar__title > h1 {
  text-decoration: none;
  color: #1d1d1b;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 1px;
  border-left: 1px solid #bababa;
  padding-left: 16px;
}

.navbar__title > small {
  font-weight: 400;
  font-size: 0.8rem;
  margin-left: 8px;
}

.navbar__centeredText {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.navbar__centeredText > h2 {
  font-weight: 300;
  font-size: 16px;
}

.navbar__menu {
  padding-right: 35px;
  margin-left: auto;
  display: flex;
  align-items: center;
}

.region__btn {
  margin-right: 10px;
}

.shoppingCart__link {
  color: var(--abb-black);
  margin-left: 42px;
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.shoppingCart__link > span {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-left: 9px;
}

.navbar__user {
  margin-top: 5px;
  margin-right: 5px;
}

#navbar__userMenu .MuiPaper-root {
  top: 65px !important;
}

#navbar__userMenu .MuiMenu-list {
  padding: 0px !important;
}

#navbar__userMenu .MuiMenuItem-root {
  margin: 0 12px !important;
  padding: 0 !important;
}

#navbar__userMenuBtn {
  margin: 0 0 5px 5px;
  /* text-transform: lowercase; */
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--abb-black);
  display: flex;
  align-items: center;
}

#navbar__userMenuBtn > .MuiSvgIcon-root:first-child {
  margin-right: 5px !important;
}

li.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-weight: 400 !important;
  font-size: 15px;
}

.nav__linkContainer {
  margin-right: 20px;
}

.link__separator {
  width: 1px;
  height: 24px;
  background-color: #bababa;
}

/* Mobile devices */
@media only screen and (max-width: 480px) {
  .navbar__title {
    margin-left: 8px;
  }

  .navbar__logo,
  .nav__linkContainer,
  .shoppingCart__link,
  .navbar__menu > span,
  .navbar__centeredText {
    display: none;
  }

  .navbar__logoTitleContainer {
    padding-left: 0;
  }

  .navbar__menu {
    padding-right: 0;
  }

  .navbar__title > a {
    text-decoration: none;
    color: var(--abb-black);
  }

  .navbar__title > a > h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    border-left: 1px solid #1f1f1f;
    padding-left: 10px;
  }

  .navbar__title > a > h1 {
    border: none;
    padding-left: 0;
  }
}

/* iPads, Tablets */
@media only screen and (min-width: 480.01px) and (max-width: 768px) {
  .navbar__logoTitleContainer {
    padding-left: 0;
  }

  .navbar__menu {
    padding-right: 12px;
  }

  .nav__linkContainer,
  .navbar__centeredText,
  .link__separator {
    display: none;
  }
}

/* Small screens, laptops */
@media only screen and (min-width: 768.01px) and (max-width: 1024px) {
  .navbar__logoTitleContainer {
    padding-left: 0;
  }
  .navbar__menu {
    padding-right: 12px;
  }
  .nav__linkContainer,
  .navbar__centeredText,
  .link__separator {
    display: none;
  }
}

/* desktops */
@media only screen and (min-width: 1024.01px) and (max-width: 1200px) {
  .navbar__logoTitleContainer {
    padding-left: 0;
  }
  .navbar__menu {
    padding-right: 12px;
  }
  .nav__linkContainer,
  .navbar__centeredText,
  .link__separator {
    display: none;
  }
}
