.recentContainer {
  width: 550px;
  float: right;
}

.recentProjects {
  flex: 1;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  box-shadow:
    0px 0px 1px rgba(15, 15, 15, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  position: relative;
  min-width: 551px;
  min-height: 283px;
}

.recentProjects__headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recentProjects__headerContainer button {
  cursor: pointer;
  margin: 30px 30px 16px 30px !important;
  text-transform: capitalize !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.recentProjects__table {
  padding: 0 30px 30px 30px;
}

.recentProjects__table tr.MuiTableRow-root:not(:last-child) td {
  border-color: #dbdbdb !important;
}

.recentProjects__table tr.MuiTableRow-root:last-child td {
  border-bottom: 1px solid #dbdbdb !important;
}

.recentProjects__table tr.MuiTableRow-root th {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #868686;
  border-bottom: 1px solid #dbdbdb !important;
}

.recentProjects__table .MuiTableCell-body {
  padding: 0 16px !important;
}

.recentProjects__tableColumnProjectName {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: unset;
  cursor: pointer;
}

.recentProjects__notification {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1f1f1f;
}

#recentProjectMenu > .MuiPaper-root > .MuiList-root {
  padding-top: 0;
  padding-bottom: 0;
}

#recentProjectMenu > .MuiPaper-root > .MuiList-root > .MuiMenuItem-root {
  padding: 8px 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

@media only screen and (max-width: 480px) {
  .recentProjects__headerContainer .recentProjects__title,
  .recentProjects__headerContainer button {
    font-size: 14px;
  }
}

@media (max-width: 1360px) {
  .recentProjects {
    min-width: 600px;
    max-width: 600px;
  }
}
