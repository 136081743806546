.iconDialog {
  width: 660px;
  /* height: 523px; */
}

/* width */
.iconDialog__icons::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.iconDialog__icons::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.iconDialog__icons::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.iconDialog__icons::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.iconDialog__info {
  width: 387px;
  height: 56px;
  border-radius: 4px;
  border: 1px solid var(--data-vis-blue-60, #1b7ac2);
  background: var(--data-vis-blue-10, #def4fa);
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconDialog__info_description {
  color: #000;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.iconDialog__info_icon {
  display: flex;
}

.icon-dialog-search-filter {
  width: 140px !important;
  height: 32px;
}

.icon-dialog-categories-files {
  width: 140px !important;
  height: 32px;
}

.icon-dialog-recently-used-button {
  text-transform: capitalize !important;
}

.icon-dialog-content-icon-size {
  display: flex;
  align-items: center;
}

.icon-dialog-button-group-icon-size {
  gap: 6px;
}

.icon-dialog-buttons {
  padding: 0px !important;
}

.icon-dialog-div-highlighted-warning {
  height: 20px;
  background-color: #f8ecac;
  margin-top: 4px;
}

.icon-dialog-text-highlighted-warning {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #868686 !important;
  padding-top: 1px;
  background-color: #f8ecac;
}

.div-text-area {
  border: 1px solid black;
  width: 100%;
  height: 60px;
  font-size: 14px;
  line-height: 14px;
  font-family: "ABBVoice";
  color: #868686;
  text-align: left;
  resize: none;
  padding: 10px 10px;
}

.public-DraftEditorPlaceholder-inner {
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  opacity: 0.5;
  user-select: none;
}

.highlight {
  background-color: #f8ecac;
}
