.designPreview {
  display: flex;
  justify-content: center;
}

.designPreview__main {
  justify-content: center;
  align-items: center;
  width: 1328px;
}

.designPreview__headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0 4px 0;
}

.designPreview__headerContainerButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 891px;
}

.designPreview__backToSwitchSelectionBtn {
  display: flex !important;
  align-items: center !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  text-transform: unset !important;
  line-height: 140% !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 0px !important;
  color: #1d1d1b !important;
}

.designPreview__projectName {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #696969;
}

.designPreview__projectName > span {
  margin-left: 4px;
  color: #000000;
}

.designPreview__mainContainer {
  width: 100% !important;
  display: flex;
  justify-content: center;
}

.designPreview__mainPreview {
  background: linear-gradient(316.08deg, #f5f5f5 52.92%, rgba(207, 207, 207, 0.36) 100%);
  border-radius: 4px;
  height: 100%;
  position: relative;
  flex: 1.6;
  align-self: stretch;
  width: 891px;
  min-width: 891px;
  max-width: 891px;
  min-height: 727px;
}

.designPreview__mainPreviewContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 30px;
}

.designPreview__mainPreviewImage--horizontal {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.designPreview__mainPreviewImage--vertical {
  transform: rotate(90deg);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.designPreview__mainPreview--horizontal {
  transition: rotate(0deg);
}

.designPreview__mainPreview--vertical {
  transform: rotate(90deg);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.designPreview__mainSummary {
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 0px 16px;
  /* width: 389px; */
}

.designPreview__mainSummary h4 {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
}

.designPreview__mainSummaryTitle > p {
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
}

.designPreview__mainSummaryTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  color: #1d1d1b;
}

.designPreview__mainSummaryDivider,
.designPreview__mainSummaryDivider_abb,
.designPreview__mainSummaryDivider_bje {
  border-top: 1px solid #bababa;
  margin: 24px 0 0 0;
}

.designPreview__mainSummaryDivider_bje {
  width: 437px;
}

.designPreview__mainGeneralCustomizationTitle,
.designPreview__mainGeneralCustomizationTitle_abb,
.designPreview__mainGeneralCustomizationTitle_bje {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.designPreview__mainGeneralCustomizationTitle_bje {
  width: 437px;
}

.designPreview__mainSummaryDesignRangeText {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  margin-bottom: 19px;
  margin-top: 24px;
}

.designPreview__mainSummaryDesignRangeText > span {
  color: #1d1d1b;
  font-weight: 300;
}

.designPreview__mainSummaryFrameColor {
  display: flex;
  justify-content: flex-start;
  gap: 11px;
  align-items: center;
}

.designPreview__mainSummaryFrameColorText > span {
  color: #1d1d1b;
  font-weight: 300;
}

.designPreview__mainSummaryFrameColorItem {
  width: 40px;
  height: 40px;
  margin-top: 16px;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
}

.designPreview__mainSummaryCoverInfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 44px;
}

.designPreview__mainSummaryCoverInfoContainer {
  padding: 16px 0;
}

.designPreview__mainSummaryCoversContainer > .designSummary__mainSummaryDivider {
  border-top: 1px solid #bababa;
  width: 100%;
  margin: 0 !important;
}

.designPreview__mainSummary > button {
  text-transform: unset !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 140% !important;
  padding: 15.5px 0 !important;
  margin: 8px 0;
}

.designPreview__mainSummaryButtons button:nth-child(1) {
  margin-top: 24px;
  font-style: normal !important;
  line-height: 140% !important;
}

.designPreview__mainSummaryButtons button:nth-child(2) {
  width: 100%;
  margin: 8px 0;
  width: 100%;
  text-transform: unset;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 140% !important;
  height: 56px !important;
}

.designPreview__mainSummaryButtons button:nth-child(3) {
  width: 100%;
  margin: 8px 0;
  width: 100%;
  text-transform: unset;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 140% !important;
  height: 56px !important;
}

.designPreview__mainSummaryCoverbtn {
  text-transform: unset !important;
  font-style: normal !important;
}

.designPreview_mainGeneralCustomizationTitleEditBtn {
  text-transform: unset !important;
  font-style: normal !important;
}

.button-templates-info {
  display: flex;
  align-items: center;
  gap: 9px;
}

.template-icon {
  width: 14px !important;
  height: 14px !important;
}

@media only screen and (max-width: 1360px) {
  .designPreview__mainPreview {
    max-width: 823px;
    min-width: 823px;
    width: 823px;
  }
}

.designPreview__favoriteDesign-hiddenContainer {
  position: absolute;
  left: -9999px;
  top: -9999px;
  width: auto;
  height: auto;
  overflow: hidden;
}

.designPreview__favoriteDesign-hiddenDiv {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
