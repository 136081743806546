.welcomePage {
  margin-top: 7px;
  overflow-x: hidden;
}

.welcomePage__backToDashboardLink {
  display: inline-block;
}

.welcomePage__backToDashboardLink > button {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-transform: unset;
  line-height: 140%;
  padding-top: 2px;
  padding-bottom: 2px;
  color: #1d1d1b !important;
}

.welcomePage__backToDashboardLink > button > svg {
  margin-right: 12px;
}

.welcomePage__demo {
  display: flex;
  align-items: center;
  margin-top: 12px;
  column-gap: 44px;
}

.welcomePage__demoImage > img {
  width: 632px;
  height: 530px;
  object-fit: cover;
  border-radius: 8px;
}

.welcomePage__demoOptions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 911px;
}

.welcomePage__demoTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 41px;
  color: #000000;
  margin-block-start: 0;
  margin-block-end: 0;
}

.welcomePage__demoOptionsBtn {
  display: flex;
  align-items: center;
}

.welcomePage__demoOptionsBtn > button.welcomePage__btn {
  font-style: normal;
  font-size: 18px;
  line-height: 140%;
  text-transform: unset;
}

.welcomePage__demoOptionsBtn > button.welcomePage__newProjectBtn {
  font-weight: 700;
  margin-right: 24px;
  padding: 15.5px 53px 15.5px 53px;
  width: 100%;
  max-width: 266px;
}

.welcomePage__demoOptionsBtn > button.welcomePage__openProjectBtn {
  font-weight: 400;
  padding: 14.5px 53px 14.5px 53px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 266px;
}

.welcomePage__demoOptionsBtn > button.welcomePage__openProjectBtn > p {
  color: #000000;
  font-weight: 500
}

.welcomePage__demoOptionsBtn > button.welcomePage__openProjectBtn.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
}

.welcomePage__demoOptionsBtn > button.welcomePage__openProjectBtn.Mui-disabled > p {
  color: rgba(0, 0, 0, 0.36);
}

.welcomePage__demoOptionsBtn > button.welcomePage__openProjectBtn.Mui-disabled path {
  fill: rgba(0, 0, 0, 0.36);
}

.welcomePage__demoOptionsBtn > button.welcomePage__openProjectBtn > svg {
  margin-right: 12px;
}

@media only screen and (max-width: 480px) {
}

/* iPads, Tablets */
@media only screen and (min-width: 480.01px) and (max-width: 768px) {
}

/* Small screens, laptops */
@media only screen and (min-width: 768.01px) and (max-width: 1024px) {
  .welcomePage__demoImage > img {
    min-width: 325px;
    max-width: 800px;
    max-height: 38vh;
  }
  .welcomePage__backToDashboardLink {
    margin-top: 20px;
  }
  .welcomePage__demoOptionsBtn > button.welcomePage__newProjectBtn,
  .welcomePage__demoOptionsBtn > button.welcomePage__openProjectBtn {
    font-size: 1rem;
    padding: 15.5px 21px 15.5px 21px;
  }
  .welcomePage__demoTitle {
    font-size: 24px;
  }
}

/* desktops */
@media only screen and (min-width: 1024.01px) and (max-width: 1200px) {
  .welcomePage__demoTitle {
    font-size: 26px;
  }
  .welcomePage__demoImage {
    flex: 1;
  }
  .welcomePage__demoOptions {
    flex: 1;
  }
  .welcomePage__demoImage > img {
    min-width: 365px;
    max-width: 900px;
    max-height: 38vh;
  }
  .welcomePage__backToDashboardLink {
    margin-top: 20px;
  }
  .welcomePage__demoOptionsBtn > button.welcomePage__newProjectBtn,
  .welcomePage__demoOptionsBtn > button.welcomePage__openProjectBtn {
    font-size: 1rem;
    padding: 15.5px 33px 15.5px 33px;
  }
}

/* large screens */
@media only screen and (min-width: 1200.01px) and (max-width: 1700px) {
  .welcomePage__demoTitle {
    font-size: 26px;
  }
  .welcomePage__backToDashboardLink {
    margin-top: 20px;
  }
  .welcomePage__demoOptionsBtn > button.welcomePage__newProjectBtn,
  .welcomePage__demoOptionsBtn > button.welcomePage__openProjectBtn {
    font-size: 1rem;
    padding: 15.5px 33px 15.5px 33px;
  }
}
