main {
  display: flex;
  position: relative;
}

.pageHeader {
  font-style: normal;
  letter-spacing: -0.7px;
}

.pageHeader > span {
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  color: #1d1d1b;
  display: block;
  margin-top: 24px;
}

.pageHeader > h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 140%;
  color: #1d1d1b;
  margin-top: 0;
  margin-bottom: 23px;
}

button.sidebar__toggleBtn {
  position: absolute;
  left: 42px;
  top: 15px;
  background-color: var(--busch-blue);
  border-radius: 50px;
  padding: 7px 7px 4px 7px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.4s;
}

button.sidebar__toggleBtn > svg {
  font-size: 1.4rem;
  fill: #fff;
}

button.sidebar__toggleBtn:hover {
  background-color: var(--busch-dark-blue);
}

section.content-section {
  margin-right: auto;
  margin-left: auto;
  max-width: 1344px;
  min-width: 1280px;
}

@media (max-width: 1290px) {
  section.content-section {
    max-width: 1280px;
    min-width: 1260px;
  }
}
