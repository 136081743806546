.MuiDialog-container .MuiPaper-root {
  width: 100%;
  max-width: 780px;
}

.favoriteDesignsPreviewDialog .closeIcon {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 35px;
  height: 35px;
}

.svgIcon {
  width: 24px;
  height: 24px;
  padding: 0;
  margin-top: 5px;
}

.favoriteDesignsPreviewContent {
  background: linear-gradient(316.08deg, #f5f5f5 52.92%, rgba(207, 207, 207, 0.36) 100%);
  border-radius: 4px;
}

.favoriteDesignsPreviewBackground {
  padding: 30px 160px 0;
  border-radius: 4px;
}

.favoriteDesignsPreview__mainPreview--horizontal-dialog {
  transition: rotate(0deg);
}