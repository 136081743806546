.design-preview-find-distributor-dialog-content {
  width: 450px;
  padding: 35px 24px 24px 24px !important;
  border-radius: 4px;
  gap: 32px;
}

.design-preview-find-distributor-dialog-image {
  background-size: 390px 179px;
  width: 390px;
  height: 179px;
  background-image: url("./elbridge.png");
  background-repeat: no-repeat;
  border-radius: 4px;
}

.design-preview-find-distributor-dialog-title {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 21px !important;
  line-height: 26px !important;
  color: #000000 !important;
  padding-top: 24px;
}

.design-preview-find-distributor-dialog-description {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #464646 !important;
  padding-top: 8px;
}

.design-preview-find-distributor-dialog-info {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  padding-top: 24px;
}

.design-preview-find-distributor-dialog-buttons {
  padding-top: 32px;
}

.design-preview-find-distributor-dialog-buttons .cancel {
  width: 193px;
  height: 41px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
}

.design-preview-find-distributor-dialog-buttons .confirm {
  width: 193px;
  height: 41px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
}
