.notFound {
  margin: auto;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%);
}

.notFound__image {
  width: 80%;
}

h1.notFound__title {
  font-family: "ABBvoice";
  font-weight: 500;
  font-size: 1.8rem;
  margin-top: -5px;
  margin-bottom: -5px;
}

p.notFound__description {
  font-size: 1.1rem;
  line-height: 1.7;
  margin: 20px 0;
}

p.notFound__description--extraMargin {
  margin: 35px 0;
}

.notFound__browserContainer {
  display: flex;
  align-items: center;
}

.browserLogo {
  width: 90px;
}

p.notFound__description > a {
  color: var(--busch-blue);
  text-decoration: underline;
  padding-left: 10px;
}

.notFound__loadBtn {
  font-family: "ABBvoice";
  font-weight: 500;
  text-decoration: none;
  background-color: var(--busch-blue);
  color: #fff;
  border-radius: 30px;
  padding: 7px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  transition: background-color 0.4s;
}

.notFound__loadBtn > svg {
  margin-right: 5px;
}

.notFound__loadBtn:hover {
  background-color: var(--busch-dark-blue);
}

@media only screen and (max-width: 480px) {
  h1.notFound__title {
    font-size: 1.2rem;
  }
  p.notFound__description {
    font-size: 1rem;
  }
  .notFound {
    width: 80%;
  }
}
