.terms-and-conditions h1 {
  font-size: 32px !important;
  line-height: 40px !important;
  margin-bottom: 52px !important;
  color: #0f0f0f !important;
}

.terms-and-conditions h2 {
  margin-bottom: 20px !important;
  font-size: 16px !important;
}

.terms-and-conditions p {
  margin-bottom: 25px !important;
}

.terms-and-conditions li {
  margin-bottom: 10px !important;
}
