.expired {
  min-height: 100vh;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
}

.expiredContainer {
  width: 100%;
  max-width: 821px;
}

.expiredDivLogo {
  height: 98px;
  display: inline-flex;
  align-items: center;
}

.expiredDivLogo > span {
  margin-bottom: 5px;
  margin-left: 16px;
}

.expiredLogo {
  width: 148px;
  height: 50px;
}

.session_expired_abb {
  width: 80px;
  height: 26.67px;
}

.expiredPaper {
  padding: 32px;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  height: 310px;

  min-height: 240px;
  border-radius: 2px;
  position: relative;
  z-index: 1;
  background: inherit;
  overflow: hidden;
}

.expiredPaper .title1 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
}

.expiredPaper .title2 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 16px;
}

.expiredPaper .message {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  white-space: pre-wrap;
}

.expiredButton {
  width: 193px;
  padding: 7px !important;
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  letter-spacing: 1.1px !important;
}
