.footer {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 60px;
  border-top: 1px solid #e7e7e7;
  justify-content: space-between;
  padding: 0 60px;
}

.footer small {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #9f9f9f;
}

.footer > div small:nth-child(2) {
  margin: 0 20px;
}

.footer small a {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.footer-copy {
  font-weight: 700 !important;
  line-height: 20px !important;
  display: flex;
  align-items: center;
}

.footer-titles {
  cursor: pointer;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 17px !important;
  color: #000000;
  margin-left: 20px !important;
}
