:root {
  --tile-59-size: 225.63779528;
  --tile-59-padding: 27.779527559;
  --tile-59-size-without-padding: calc(var(--tile-59-size) - (2 * var(--tile-59-padding)));
  --tile-59-separation-line-width: 1;
  --tile-59-space-for-separation-line: 2;
  --tile-59-one-rocker-space-between-tiles: 1;
  --tile-59-two-rockers-space-between-tiles: 1;
  --tile-59-one-slider-one-rocker-space-between-tiles: 1;
  --tile-59-one-button-one-rocker-space-between-tiles: 1;
  --tile-59-two-buttons-one-rocker-space-between-tiles: 1;
  --tile-59-slider-two-buttons-space-between-tiles: 4;
}

.tile-59 {
  height: calc(var(--tile-59-size) * 1px);
  padding: calc(var(--tile-59-padding) * 1px);
}

.tile-59 .container {
  --container-space-between-tiles: calc(1 * var(--tile-59-space-for-separation-line));
  width: calc((var(--tile-59-size-without-padding) - var(--container-space-between-tiles)) / 2 * 1px);
  max-width: calc((var(--tile-59-size-without-padding) - var(--container-space-between-tiles)) / 2 * 1px);
}

.tile-59 .tile-div-canvas {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  position: absolute;
}

.tile-59 .tile-div-canvas.default {
  top: 32px;
}

.tile-59 .tile-div-canvas.button-rocker {
  top: 28px;
}

.tile-59 .tile-div-canvas.function {
  top: 26px;
}

.tile-59 .tile-div-canvas canvas {
  flex: 1 1 auto;
}

.tile-59 .tile-div-canvas.without-icon {
  top: 30px;
}

.tile-59 .tile-div-canvas.without-icon-preview {
  top: -2px;
}

.tile-59 .direction-column {
  height: 100%;
}

.tile-59 .tile-border {
  border: 1px dashed #bababa;
}

.tile-59 .tile-border-rocker {
  --tile-border-rocker-space-between-tiles: calc(1 * var(--tile-59-space-for-separation-line));
  width: calc((var(--tile-59-size-without-padding) - var(--tile-border-rocker-space-between-tiles)) / 2 * 1px);
  max-width: calc((var(--tile-59-size-without-padding) - var(--tile-border-rocker-space-between-tiles)) / 2 * 1px);
}

.tile-59 .tile-border-rocker-three {
  --tile-border-rocker-three-space-between-tiles: calc(2 * var(--tile-59-one-button-one-rocker-space-between-tiles));
  width: calc((var(--tile-59-size-without-padding) - var(--tile-border-rocker-three-space-between-tiles)) / 3 * 1px);
  max-width: calc(
    (var(--tile-59-size-without-padding) - var(--tile-border-rocker-three-space-between-tiles)) / 3 * 1px
  );
}

.tile-59 .rocker-padding-top {
  padding-top: 10px !important;
}

.tile-59 .box-icon {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.tile-59 .border-icon-function-preview {
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 50% !important;
  margin-bottom: 6px;
}

.tile-59 .abb-border-icon-function-with-canvas,
.tile-59 .bje-border-icon-function-with-canvas {
  width: 32px;
  height: 32px;
  border-radius: 50% !important;
  border-top: 1px dashed #bababa;
  border-left: 1px dashed #bababa;
  border-right: 1px dashed #bababa;
  border-bottom: 1px dashed transparent;
}

.tile-59 .abb-border-icon-function-with-canvas-preview,
.tile-59 .bje-border-icon-function-with-canvas-preview {
  width: 32px;
  height: 32px;
  border-bottom: none;
}

.tile-59 .abb-border-icon-control-with-canvas,
.tile-59 .bje-border-icon-control-with-canvas {
  width: 34px;
  height: 34px;
  border-radius: 50% !important;
  border-top: 1px dashed #bababa;
  border-left: 1px dashed #bababa;
  border-right: 1px dashed #bababa;
  border-bottom: 1px dashed transparent;
}

.tile-59 .abb-border-icon-control-with-canvas-preview,
.tile-59 .bje-border-icon-control-with-canvas-preview {
  width: 37px;
  height: 37px;
  border-bottom: none;
}

.tile-59 .bje-border-icon-control,
.tile-59 .abb-border-icon-control {
  width: 37px;
  height: 37px;
  border: 1px dashed #9f9f9f !important;
  border-radius: 50% !important;
  /* margin-bottom: 1px; */
}

.tile-59 .bje-border-icon-function,
.tile-59 .abb-border-icon-function {
  width: 32px;
  height: 32px;
  border: 1px dashed #9f9f9f !important;
  border-radius: 50% !important;
  /* margin-bottom: 6px; */
}

.tile-59 .bje-border-icon-control:hover {
  border-color: #009fe3 !important;
}

.tile-59 .bje-border-icon-function:hover {
  border-color: #009fe3 !important;
}

.tile-59 .abb-border-icon-control:hover {
  border-color: #ff000f !important;
}

.tile-59 .abb-border-icon-function:hover {
  border-color: #ff000f !important;
}

.tile-59 .border-icon-control-preview {
  width: 37px;
  height: 37px;
  border: none;
  border-radius: 50% !important;
  margin-bottom: 1px;
}

.tile-59 .tile-border-preview {
  border: 1px dashed transparent;
}

.tile-59 .textarea {
  width: 100%;
  border: none;
  resize: none;
  text-align: center;
  background-color: transparent;
  line-height: 77%;
}

.tile-59 .textarea:focus {
  outline: none;
}

.tile-59 .textarea::placeholder {
  font-size: 10pt;
  padding-top: 3px !important;
}

.tile-59 .printable-area-four-button {
  height: calc((var(--tile-59-size-without-padding) - var(--tile-59-space-for-separation-line)) / 2 * 1px);
}

.tile-59 .printable-area-one-button-one-rocker {
  height: calc((var(--tile-59-size-without-padding) - var(--tile-59-space-for-separation-line)) / 3 * 1px);
}

.tile-59 .printable-area-two-buttons-one-rocker-function {
  height: calc((var(--tile-59-size-without-padding) - var(--tile-59-space-for-separation-line)) / 2 * 1px);
}

.tile-59 .printable-area-two-buttons-one-rocker-control {
  height: calc((var(--tile-59-size-without-padding) - var(--tile-59-space-for-separation-line)) / 2 * 1px);
}

.tile-59 .printable-area-rocker {
  --space-between-tiles: calc(2 * var(--tile-59-one-rocker-space-between-tiles));
  height: calc((var(--tile-59-size-without-padding) - var(--space-between-tiles)) / 3 * 1px);
}

.tile-59 .printable-area-two-rockers {
  --space-between-tiles: calc(2 * var(--tile-59-one-rocker-space-between-tiles));
  height: calc((var(--tile-59-size-without-padding) - var(--space-between-tiles)) / 3 * 1px);
}

/* ROCKER */
.tile-59 .rocker-margin-bottom {
  margin-bottom: calc(var(--tile-59-one-rocker-space-between-tiles) / 2 * 1px);
}

.tile-59 .rocker-margin-top {
  margin-top: calc(var(--tile-59-one-rocker-space-between-tiles) / 2 * 1px);
}

/* TWO BUTTONS */
.tile-59 .two-buttons-margin-left {
  margin-left: calc((var(--tile-59-space-for-separation-line) / 2) * 1px);
}

.tile-59 .two-buttons-margin-right {
  margin-right: calc((var(--tile-59-space-for-separation-line) / 2) * 1px);
}

/* TWO ROCKERS */
.tile-59 .two-rockers-margin-bottom {
  margin-bottom: calc(var(--tile-59-two-rockers-space-between-tiles) / 2 * 1px);
}

.tile-59 .two-rockers-margin-left {
  margin-left: calc(var(--tile-59-space-for-separation-line) / 2 * 1px);
}

.tile-59 .two-rockers-margin-right {
  margin-right: calc(var(--tile-59-space-for-separation-line) / 2 * 1px);
}

.tile-59 .two-rockers-margin-top {
  margin-top: calc(var(--tile-59-two-rockers-space-between-tiles) / 2 * 1px);
}

/* ONE BUTTON ONE ROCKER */
.tile-59 .one-button-one-rocker-margin-bottom {
  margin-bottom: calc((var(--tile-59-one-button-one-rocker-space-between-tiles) / 2) * 1px);
}

.tile-59 .one-button-one-rocker-margin-right {
  margin-right: calc(var(--tile-59-space-for-separation-line) / 2 * 1px);
}

.tile-59 .one-button-one-rocker-margin-left {
  margin-left: calc(var(--tile-59-space-for-separation-line) / 2 * 1px);
}

.tile-59 .one-button-one-rocker-margin-top {
  margin-top: calc((var(--tile-59-one-button-one-rocker-space-between-tiles) / 2) * 1px);
}

/* TWO BUTTONS ONE ROCKER */
.tile-59 .two-buttons-one-rocker-margin-bottom {
  margin-bottom: calc((var(--tile-59-space-for-separation-line)) / 2 * 1px);
}

.tile-59 .two-buttons-one-rocker-margin-left {
  margin-left: calc(var(--tile-59-two-buttons-one-rocker-space-between-tiles) / 2 * 1px);
}

.tile-59 .two-buttons-one-rocker-margin-right {
  margin-right: calc(var(--tile-59-two-buttons-one-rocker-space-between-tiles) / 2 * 1px);
}

.tile-59 .two-buttons-one-rocker-margin-top {
  margin-top: calc((var(--tile-59-space-for-separation-line)) / 2 * 1px);
}

.tile-59 .two-buttons-one-rocker-margin-left-btn {
  margin-left: calc(var(--tile-59-space-for-separation-line) / 2 * 1px);
}

.tile-59 .two-buttons-one-rocker-margin-right-btn {
  margin-right: calc(var(--tile-59-space-for-separation-line) / 2 * 1px);
}

/* FOUR BUTTONS */
.tile-59 .four-buttons-margin-bottom {
  margin-bottom: calc((var(--tile-59-space-for-separation-line) - var(--tile-59-separation-line-width)) / 2 * 2px);
}

.tile-59 .four-buttons-margin-left {
  margin-left: calc((var(--tile-59-space-for-separation-line) - var(--tile-59-separation-line-width)) / 2 * 2px);
}

.tile-59 .four-buttons-margin-top {
  margin-top: calc((var(--tile-59-space-for-separation-line) - var(--tile-59-separation-line-width)) / 2 * 2px);
}

.tile-59 .four-buttons-margin-right {
  margin-right: calc((var(--tile-59-space-for-separation-line) - var(--tile-59-separation-line-width)) / 2 * 2px);
}

/* Slider */
.tile-59 .slider {
  color: #000000;
  width: 0.35mm;
  margin: 0px;
}

.tile-59 .slider-top {
  height: 47px;
}

.tile-59 .slider-top-only-icon {
  height: 9.3mm;
}

.tile-59 .slider-top-with-icon-and-canvas {
  height: 47px;
}

.tile-59 .tile-border-preview .slider-top-with-icon-and-canvas {
  height: 46px;
}

.tile-59 .slider-top-only-canvas {
  height: 47px;
}
.tile-59 .tile-border-preview .slider-top-only-canvas {
  height: 47px;
  margin-bottom: 15px;
}

.tile-59 .slider-vertical-left {
  --webkit-transform: rotate(90deg);
}

.tile-59 .slider-vertical-right {
  --webkit-transform: rotate(90deg);
}

.tile-59 .slider-bottom {
  height: 83px;
}

.tile-59 .slider-bottom-only-icon {
  height: 83px;
}

.tile-59 .slider-bottom-with-icon-and-canvas {
  height: 75px;
  margin-top: 10px;
}

.tile-59 .slider-bottom-only-canvas {
  height: 75px;
  margin-top: 10px;
}
.tile-59 .tile-border-preview .slider-bottom-only-canvas {
  height: 83px;
  margin-top: 22px;
}

/* SLIDER TWO BUTTONS */
.tile-59 .slider-two-buttons-margin-right {
  margin-right: calc((var(--tile-59-space-for-separation-line) / 2) * 1px);
}

.tile-59 .slider-two-buttons-margin-left {
  margin-left: calc((var(--tile-59-space-for-separation-line) / 2) * 1px);
}

.tile-59 .slider-two-buttons-margin-bottom {
  margin-bottom: calc((var(--tile-59-space-for-separation-line) / 2) * 1px);
}

.tile-59 .slider-two-buttons-margin-top {
  margin-top: calc((var(--tile-59-space-for-separation-line) / 2) * 1px);
}

.tile-59 .printable-area-slider-two-buttons {
  height: calc((var(--tile-59-size-without-padding) - var(--tile-59-space-for-separation-line)) / 2 * 1px);
}

/* Two Sliders */
.tile-59 .two-sliders-margin-left {
  margin-left: calc((var(--tile-59-space-for-separation-line) / 2) * 1px);
}

.tile-59 .two-sliders-margin-right {
  margin-right: calc((var(--tile-59-space-for-separation-line) / 2) * 1px);
}

/* ONE SLIDER ONE ROCKER */
.tile-59 .one-slider-one-rocker-margin-right {
  margin-right: calc((var(--tile-59-space-for-separation-line) / 2) * 1px);
}

.tile-59 .one-slider-one-rocker-margin-left {
  margin-left: calc((var(--tile-59-space-for-separation-line) / 2) * 1px);
}

.tile-59 .one-slider-one-rocker-margin-bottom {
  margin-bottom: calc(var(--tile-59-one-slider-one-rocker-space-between-tiles) / 2 * 1px);
}

.tile-59 .printable-area-one-slider-one-rocker {
  --space-between-tiles: calc(2 * var(--tile-59-one-rocker-space-between-tiles));
  height: calc((var(--tile-59-size-without-padding) - var(--space-between-tiles)) / 3 * 1px);
}

.tile-59 .one-slider-one-rocker-margin-top {
  margin-top: calc(var(--tile-59-one-slider-one-rocker-space-between-tiles) / 2 * 1px);
}

/* ONE SLIDER ONE BUTTON */
.tile-59 .one-slider-one-button-margin-right {
  margin-right: calc((var(--tile-59-space-for-separation-line) / 2) * 1px);
}

.tile-59 .one-slider-one-button-margin-left {
  margin-left: calc((var(--tile-59-space-for-separation-line) / 2) * 1px);
}
